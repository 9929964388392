import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { changeWebsiteTitle, gotoPlatform, Lang, OtherPlatform } from '../utils/util';
import classes from './HomePage.module.scss';
interface Props {
  language: Lang;
}
enum RefSection {
  SECTION_1 = 's1',
  SECTION_2 = 's2',
  SECTION_3 = 's3',
  SECTION_4 = 's4',
  SECTION_5 = 's5',
  SECTION_6 = 's6',
}

const HomePage: React.FC<Props> = (props) => {
  const { hash } = useLocation();
  useEffect(() => {
    changeWebsiteTitle(props.language);
    const removedHashCharaterString = hash.slice(1);

    if (hash !== undefined) {
      const refSection = removedHashCharaterString as RefSection;
      switch (refSection) {
        case RefSection.SECTION_1:
          scrollToSection(refSection1);
          break;
        case RefSection.SECTION_2:
          scrollToSection(refSection2);
          break;
        case RefSection.SECTION_3:
          scrollToSection(refSection3);
          break;
        case RefSection.SECTION_4:
          scrollToSection(refSection4);
          break;
        case RefSection.SECTION_5:
          scrollToSection(refSection5);
          break;
        case RefSection.SECTION_6:
          scrollToSection(refSection6);
          break;
      }
    }
  }, []);

  const refSection1 = useRef<HTMLElement>(null);
  const refSection2 = useRef<HTMLElement>(null);
  const refSection3 = useRef<HTMLElement>(null);
  const refSection4 = useRef<HTMLElement>(null);
  const refSection5 = useRef<HTMLElement>(null);
  const refSection6 = useRef<HTMLElement>(null);
  const scrollToSection = (ref: React.RefObject<HTMLElement>) => {
    const XXXEl = ref.current;
    if (!XXXEl) return;
    XXXEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const [lang, setLang] = useState(props.language);

  // image
  const imgLogo =
    lang === Lang.zh ? require('../images/logo_with_desc.png') : require('../images/logo_with_desc_en.png');

  const imgSection3Title =
    lang === Lang.zh
      ? require('../images/page1/section3/header.png')
      : require('../images/page1/section3/header_en.png');

  const imgSection4Text =
    lang === Lang.zh ? require('../images/page1/section4/text.png') : require('../images/page1/section4/text_en.png');

  const imgSection6MoreBtn =
    lang === Lang.zh ? require('../images/page1/section6/btn_page2.png') : require('../images/page1/more_btn_en_2.png');

  const imgSection7MoreBtn =
    lang === Lang.zh ? require('../images/page1/section7/text.png') : require('../images/page1/section7/text_en.png');

  const imgSection8MoreBtn =
    lang === Lang.zh ? require('../images/page1/section8/btn_page2.png') : require('../images/page1/more_btn_en.png');

  const imgSection9Header =
    lang === Lang.zh
      ? require('../images/page1/section9/header.png')
      : require('../images/page1/section9/header_en.png');

  return (
    <div className={classes.homePage}>
      {/*red area */}
      <div id={classes.wrapper}>
        {/* section 1 */}
        <section ref={refSection1}>
          <div id={classes.header}>
            {/*image background */}
            <img
              loading="lazy"
              src={require('../images/background/homepage_background_1.png')}
              alt={'background'}
              style={{ position: 'absolute', left: 0, height: 945, width: '100vw', zIndex: -1 }}
            />

            <div className={classes.headerBG}>
              <div className={classes.absoluteWrap}>
                <div className={classes.headerMenuRow}>
                  {/*menu */}
                  <div className={classes.menu}>
                    <button
                      className={classes.menuBtn}
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                      style={{ fontSize: lang === Lang.zh ? 16 : 13 }}
                    >
                      {homePageScript.get(`menuBtnHomeSection1_${lang}`)}
                    </button>
                    <button
                      className={classes.menuBtn}
                      onClick={() => {
                        scrollToSection(refSection2);
                      }}
                      style={{ fontSize: lang === Lang.zh ? 16 : 13 }}
                    >
                      {homePageScript.get(`menuBtnHomeSection2_${lang}`)}
                    </button>
                    <button
                      className={classes.menuBtn}
                      onClick={() => {
                        scrollToSection(refSection3);
                      }}
                      style={{ fontSize: lang === Lang.zh ? 16 : 13 }}
                    >
                      {homePageScript.get(`menuBtnHomeSection3_${lang}`)}
                    </button>
                    <button
                      className={classes.menuBtn}
                      onClick={() => {
                        scrollToSection(refSection4);
                      }}
                      style={{ fontSize: lang === Lang.zh ? 16 : 13 }}
                    >
                      {homePageScript.get(`menuBtnHomeSection4_${lang}`)}
                    </button>
                    <button
                      className={classes.menuBtn}
                      onClick={() => {
                        scrollToSection(refSection5);
                      }}
                      style={{ fontSize: lang === Lang.zh ? 16 : 13 }}
                    >
                      {homePageScript.get(`menuBtnHomeSection5_${lang}`)}
                    </button>
                    <button
                      className={classes.menuBtn}
                      onClick={() => {
                        scrollToSection(refSection6);
                      }}
                      style={{ fontSize: lang === Lang.zh ? 16 : 13 }}
                    >
                      {homePageScript.get(`menuBtnHomeSection6_${lang}`)}
                    </button>
                  </div>
                </div>
                <div className={classes.contentMediaRow}>
                  <div className={classes.headerText}>
                    <img
                      loading="lazy"
                      src={require(`../images/booking_btn_${lang}.png`)}
                      width={100}
                      height={27}
                      alt={'booking_btn'}
                      onClick={() =>
                        gotoPlatform(lang === Lang.zh ? OtherPlatform.InlineApp_zh : OtherPlatform.InlineApp_en)
                      }
                    />
                    <img
                      loading="lazy"
                      src={require('../images/fb.png')}
                      width={25}
                      height={25}
                      style={{ marginLeft: 16 }}
                      alt={'fb'}
                      onClick={() => gotoPlatform(OtherPlatform.Facebook)}
                    />
                    <img
                      loading="lazy"
                      src={require('../images/instagram.png')}
                      width={25}
                      height={25}
                      style={{ marginLeft: 16 }}
                      alt={'instagram'}
                      onClick={() => gotoPlatform(OtherPlatform.Instagram)}
                    />
                    <img
                      loading="lazy"
                      src={require('../images/whatsapp.png')}
                      width={25}
                      height={25}
                      style={{ marginLeft: 16, marginRight: 16 }}
                      alt={'instagram'}
                      onClick={() => gotoPlatform(OtherPlatform.Whatsapp)}
                    />
                    <Link
                      to="/"
                      onClick={() => {
                        setLang(Lang.zh);
                        changeWebsiteTitle(Lang.zh);
                      }}
                    >
                      中
                    </Link>{' '}
                    <div style={{ marginLeft: 10, marginRight: 10 }}>/</div>{' '}
                    <Link
                      to="/en"
                      onClick={() => {
                        setLang(Lang.en);
                        changeWebsiteTitle(Lang.en);
                      }}
                    >
                      ENG
                    </Link>
                  </div>
                </div>
                <div className={classes.slide1}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                        width: 450,
                        // height: 300,
                      }}
                    >
                      <img
                        loading="lazy"
                        src={imgLogo}
                        width={450}
                        // height={327}
                        className={classes.headerImage}
                        style={{ flexShrink: 0 }}
                        alt={'logo'}
                      />
                    </div>
                    <br />
                    <br />
                    <br />

                    <div className={classes.text} style={{ width: 400, fontSize: 14, lineHeight: 2 }}>
                      {homePageScript.get(`section1Text_${lang}`)}
                    </div>
                    {lang === Lang.zh ? <br /> : null}
                    {lang === Lang.zh ? (
                      <div className={classes.text} style={{ width: 400, fontSize: 14, lineHeight: 2 }}>
                        {homePageScript.get(`section1Text_2_${lang}`)}
                      </div>
                    ) : null}
                  </div>
                  <img
                    loading="lazy"
                    src={require('../images/homepage_image.png')}
                    width={820}
                    height={720}
                    alt={'cover'}
                    style={{ position: 'relative', right: 70 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <img loading="lazy" src={require('../images/page1/divider.png')} height={20} width={'100%'} alt="divider" />
        {/* section 2 */}
        <section ref={refSection2}>
          <div id={classes.headerSection2} style={{ height: lang === Lang.zh ? 1060 : 1200 }}>
            <img
              loading="lazy"
              src={require('../images/background/homepage_background_1_reflected.png')}
              alt={'background'}
              style={{ position: 'absolute', left: 0, bottom: 0, height: '100vh', width: '100vw', zIndex: -1 }}
            />
            <div className={classes.absoluteWrap}>
              <div className={classes.row} style={{ marginTop: 40 }}>
                <div className={classes.column} style={{ marginRight: 95 }}>
                  <h1 style={{ fontSize: 25, marginBottom: 20, color: '#c2a26b' }}>
                    {homePageScript.get(`section2Header_${lang}`)}
                  </h1>
                  <h1 style={{ fontSize: 28, marginBottom: 10, color: '#c2a26b' }}>
                    {homePageScript.get(`section2Header2_${lang}`)}
                  </h1>
                  <h2 style={{ color: '#FFF' }}>{homePageScript.get(`section2Text_${lang}`)}</h2>
                </div>
                <img
                  loading="lazy"
                  src={require('../images/page1/section2/farm1.png')}
                  width={487.5}
                  height={326}
                  className={classes.headerImage}
                  alt={''}
                />
              </div>
              <div className={classes.row} style={{ marginTop: 20 }}>
                <img
                  loading="lazy"
                  src={require('../images/page1/section2/pork1.png')}
                  width={450}
                  height={300}
                  className={classes.headerImage}
                  alt={''}
                />
                <div className={classes.column} style={{ marginLeft: 50 }}>
                  <h1 style={{ fontSize: 23, color: '#c2a26b', marginTop: 0 }}>
                    {homePageScript.get(`section2Header3_${lang}`)}
                  </h1>
                  <h2 style={{ color: '#FFF', margin: 0 }}>{homePageScript.get(`section2Text2_${lang}`)}</h2>
                </div>
              </div>
              <div className={classes.row} style={{ marginTop: 20, alignItems: 'flex-end' }}>
                <img
                  loading="lazy"
                  src={require('../images/page1/section2/farm2.png')}
                  width={330}
                  height={265}
                  alt={''}
                />
                <div style={{ width: 20 }}></div>
                <pre style={{ color: '#FFF' }}>
                  {homePageScript.get(`section2PreLeft1_${lang}`)}
                  {'\n'}
                  {homePageScript.get(`section2PreLeft2_${lang}`)}
                  {'\n'}
                  {homePageScript.get(`section2PreLeft3_${lang}`)}
                  {'\n'}
                  ── 桜花牧場歷年獲得獎項──{'\n\n'}
                  平成16年(2004年)JA都城 支所牛品評會 チャンピオン(冠軍){'\n'}
                  平成24年(2012年)JA都城 西部支部 枝肉共励会 最優秀賞{'\n'}
                  平成25年(2013年)JA都城 支所牛品評會 チャンピオン(冠軍){'\n'}
                  平成25年(2013年)JA都城 上物率の部(5等級率) 第一位{'\n'}
                  平成25年(2013年)第129回 JA都城 枝肉共励会 最優秀賞{'\n'}
                  平成26年(2014年)JA都城 西部支部 枝肉共励会 最優秀賞{'\n'}
                  平成26年(2014年)第130回 JA都城 枝肉共励会 最優秀賞{'\n'}
                  平成27年(2015年)第134回 JA都城 枝肉共励会 優秀賞{'\n'}
                  平成28年(2016年)JA都城 3支部合同枝肉共励会 優秀賞{'\n'}
                  平成28年(2016年)JA都城 西部支部 枝肉共励会 最優秀賞{'\n'}
                  平成28年(2016年)JA都城 上物率の部(5等級率) 第一位{'\n'}
                </pre>
                <div style={{ width: 20 }}></div>

                <img
                  loading="lazy"
                  src={require('../images/page1/section2/image.png')}
                  width={330}
                  height={265}
                  alt={''}
                />
                <div style={{ width: 20 }}></div>

                <pre style={{ color: '#FFF' }}>
                  平成28年(2016年)JA都城 西部支部 枝肉共励会 優秀賞{'\n'}
                  平成28年(2016年)第137回 JA都城 枝肉共励会 優秀賞{'\n'}
                  平成29年(2017年)JA都城 上物率の部(5等級率) 第一位{'\n'}
                  平成29年(2017年)第139回 JA都城 枝肉共励会 優秀賞{'\n'}
                  平成29年(2017年)第140回 JA都城 枝肉共励会 最優秀賞{'\n'}
                  令和元年(2019年)第43回 九州管內系統和牛枝肉共励会 銅賞{'\n'}
                  令和2年(2020年)JA宮崎経済連系統和牛枝肉共励会 金賞{'\n'}
                  令和2年(2020年)第148回 JA都城 枝肉共進会 最優秀賞{'\n'}
                  令和2年(2020年)JA宮崎経済連系統和牛枝肉共励会 銀賞{'\n'}
                  令和2年(2020年)宮崎縣知事頒發表彰狀{'\n'}
                  令和3年(2021年)第63回宮崎県畜產共進会(肉牛枝肉の部)優等賞{'\n'}
                  令和3年(2021年)JA宮崎経済連系統和牛枝肉共励会 優秀賞{'\n'}
                </pre>
              </div>
            </div>
          </div>
        </section>
        {/* section 3 */}
        <section ref={refSection3}>
          <div id={classes.headerSection3}>
            <img
              loading="lazy"
              src={require('../images/page1/section3/background.png')}
              alt={'background'}
              style={{ position: 'absolute', left: 0, bottom: 0, height: 700, width: '100vw', zIndex: -1 }}
            />
            <div className={classes.absoluteWrap}>
              <div
                style={{
                  position: 'absolute',
                  width: 1200,
                  bottom: 0,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: lang === Lang.zh ? 50 : 30,
                  marginLeft: lang === Lang.zh ? 0 : 100,
                }}
              >
                <div
                  className={classes.column}
                  style={{
                    fontSize: 22,
                    color: '#633920',
                    width: 300,
                    fontWeight: 900,
                  }}
                >
                  {homePageScript.get(`section3TipTextHeader_${lang}`)}
                  <h3>{homePageScript.get(`section3TipText1_${lang}`)}</h3>
                  <div style={{ height: lang === Lang.zh ? 15 : 10 }} />
                  <h3>{homePageScript.get(`section3TipText2_${lang}`)}</h3>
                  <div style={{ height: lang === Lang.zh ? 15 : 10 }} />
                  <h3>{homePageScript.get(`section3TipText3_${lang}`)}</h3>
                  <div style={{ height: lang === Lang.zh ? 15 : 10 }} />
                  <h3 style={{ marginBottom: 0 }}>{homePageScript.get(`section3TipText4_${lang}`)}</h3>
                </div>
              </div>
              <img loading="lazy" src={imgSection3Title} width={240} className={classes.headerImage} alt={''} />
              <div
                className={classes.row}
                style={{
                  justifyContent: 'space-between',
                  width: 1200,
                  alignItems: 'center',
                }}
              >
                <pre style={{ color: '#633920', fontSize: 14, textAlign: 'left', fontWeight: 900, marginBottom: 120 }}>
                  {homePageScript.get(`section3CowSectionLeft_${lang}`)}
                </pre>
                <img
                  loading="lazy"
                  src={require('../images/page1/section3/cow_section.png')}
                  height={530}
                  className={classes.headerImage}
                  alt={''}
                />
                <pre style={{ color: '#633920', fontSize: 14, textAlign: 'left', marginBottom: 250, fontWeight: 900 }}>
                  {homePageScript.get(`section3CowSectionRight_${lang}`)}
                </pre>
              </div>
            </div>
          </div>
        </section>
        {/* section 4 */}
        <section ref={refSection4}>
          <div id={classes.headerSection4}>
            <div className={classes.absoluteWrap}>
              <div className={classes.textImg}>
                <img loading="lazy" src={imgSection4Text} height={300} alt="宮崎肉本舗" />
              </div>
            </div>
          </div>
        </section>
        {/* section 5 */}
        <div id={classes.headerSection5}>
          <img
            loading="lazy"
            src={require('../images/page1/section4/background_banner.png')}
            alt={'background'}
            style={{
              position: 'absolute',
              height: '140px',
              width: '100vw',
              marginTop: -19,
              objectFit: 'fill',
              left: 0,
              zIndex: 0,
            }}
          />
          <div className={classes.absoluteWrap}>
            <div className={classes.btn} style={{ zIndex: 1 }}>
              <img
                loading="lazy"
                src={require('../images/page1/section4/btn_background.png')}
                alt={'background'}
                style={{
                  position: 'absolute',
                  zIndex: -1,
                  width: 300,
                  height: 93,
                  marginTop: 5,
                }}
              />
              {homePageScript.get(`section5Pork1Text1_${lang}`)}
              <br />
              {homePageScript.get(`section5Pork1Text2_${lang}`)}
              <br />
              <div style={{ fontSize: 16 }}>{homePageScript.get(`section5Pork1Text3_${lang}`)}</div>
            </div>
            <div className={classes.btn} style={{ zIndex: 1 }}>
              <img
                loading="lazy"
                src={require('../images/page1/section4/btn_background.png')}
                alt={'background'}
                style={{
                  position: 'absolute',
                  zIndex: -1,
                  width: 300,
                  height: 93,
                  marginTop: 5,
                }}
              />
              {homePageScript.get(`section5Pork2Text1_${lang}`)}
              <br />
              {homePageScript.get(`section5Pork2Text2_${lang}`)}
              <br />
              <div style={{ fontSize: 16 }}>{homePageScript.get(`section5Pork2Text3_${lang}`)}</div>
            </div>
          </div>
        </div>
        {/* section 6 */}
        <div id={classes.headerSection6}>
          <div className={classes.absoluteWrap}>
            <div className={classes.row} style={{ marginBottom: 0, marginTop: 20 }}>
              <div
                style={{
                  height: 362,
                  width: 600,
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  justifyContent: 'center',
                  zoom: 0.9,
                }}
              >
                <img
                  loading="lazy"
                  src={require('../images/page2/Set_01.jpg')}
                  width={350}
                  className={classes.img}
                  style={{ zIndex: -2, marginBottom: 40 }}
                  alt={''}
                />
                <img
                  loading="lazy"
                  src={require('../images/page1/section6/cow_name_plate.png')}
                  style={{ position: 'absolute', left: 50, top: 0, height: 150 }}
                  alt={''}
                />
                <div
                  style={{
                    position: 'absolute',
                    left: 100,
                    bottom: 30,
                    color: '#FFF',
                    textAlign: 'left',
                    fontSize: 20,
                    zoom: 0.5,
                  }}
                >
                  {homePageScript.get(`section6remark1_${lang}`)}
                  <br />
                  {homePageScript.get(`section6remark2_${lang}`)}
                </div>
              </div>
              <div
                style={{
                  height: 362,
                  width: 600,
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  justifyContent: 'center',
                  zoom: 0.9,
                }}
              >
                <img
                  loading="lazy"
                  src={require('../images/page2/Set_02.jpg')}
                  width={370}
                  className={classes.img}
                  style={{ zIndex: -2, marginBottom: 50 }}
                  alt={''}
                />
                <div
                  style={{
                    position: 'absolute',
                    left: 0,
                    bottom: 30,
                    color: '#FFF',
                    textAlign: 'left',
                    fontSize: 20,
                    zoom: 0.5,
                    marginLeft: 60,
                  }}
                >
                  {homePageScript.get(`section6remark1_${lang}`)}
                  <br />
                  {homePageScript.get(`section6remark2_${lang}`)}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* section 5.1 */}
        <div id={classes.headerSection5} style={{ marginTop: 10 }}>
          <img
            loading="lazy"
            src={require('../images/page1/section4/background_banner.png')}
            alt={'background'}
            style={{
              position: 'absolute',
              height: '140px',
              width: '100vw',
              marginTop: -19,
              objectFit: 'fill',
              left: 0,
              zIndex: 0,
            }}
          />
          <div className={classes.absoluteWrap}>
            <div className={classes.btn} style={{ zIndex: 1 }}>
              <img
                loading="lazy"
                src={require('../images/page1/section4/btn_background.png')}
                alt={'background'}
                style={{
                  position: 'absolute',
                  zIndex: -1,
                  width: 300,
                  height: 93,
                  marginTop: 5,
                }}
              />
              {homePageScript.get(`section5Pork3Text1_${lang}`)}
              <br />

              {/* {lang === Lang.zh ? homePageScript.get(`section5Pork3Text2_en`) : ''} */}
              <div style={{ fontSize: 16 }}>{homePageScript.get(`section5Pork3Text3_${lang}`)}</div>
            </div>
          </div>
        </div>
        {/* section 6.1 */}
        <div id={classes.headerSection6}>
          <div className={classes.absoluteWrap}>
            <div className={classes.row} style={{ marginBottom: 0, marginTop: 20 }}>
              <div
                style={{
                  height: 362,
                  width: 600,
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  justifyContent: 'center',
                  zoom: 0.9,
                }}
              >
                <img
                  loading="lazy"
                  src={require('../images/page2/Set_03.jpg')}
                  width={370}
                  className={classes.img}
                  style={{ zIndex: -2 }}
                  alt={''}
                />
                <div
                  style={{
                    position: 'absolute',
                    left: 100,
                    bottom: 30,
                    color: '#FFF',
                    textAlign: 'left',
                    fontSize: 20,
                    zoom: 0.5,
                  }}
                >
                  {homePageScript.get(`section6remark1_${lang}`)}
                  <br />
                  {homePageScript.get(`section6remark2_${lang}`)}
                </div>
              </div>
            </div>
            <div
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 250 }}
            >
              <Link to={lang === Lang.zh ? '/menu_zh' : '/menu_en'} onClick={() => window.scrollTo(0, 0)}>
                <img loading="lazy" src={imgSection6MoreBtn} width={lang === Lang.zh ? 300 : 260} alt={''} />
              </Link>
              <a href={require(`../documents/lunch_menu.pdf`)} rel="noopener noreferrer" target="_blank">
                <img
                  loading="lazy"
                  src={require(`../images/lunch_menu_${lang}.png`)}
                  width={lang === Lang.zh ? 300 : 260}
                  alt={''}
                  style={{ marginLeft: 30, objectFit: 'fill', marginTop: 5 }}
                />
              </a>
            </div>
          </div>
        </div>
        {/* section 7 */}
        <div id={classes.headerSection7}>
          <div className={classes.absoluteWrap}>
            <img loading="lazy" src={imgSection7MoreBtn} height={124.69} alt={''} />
          </div>
        </div>
        {/* section 8 */}
        <div id={classes.headerSection8}>
          <div className={classes.absoluteWrap}>
            <div className={classes.column} style={{ height: 150 }}>
              <h2
                className={classes.header}
                style={{ color: '#c2a26b', height: '100%', display: 'flex', alignItems: 'center' }}
              >
                {homePageScript.get(`section8Header_${lang}`)}
              </h2>
            </div>
            <div className={classes.row}>
              <div className={classes.column}>
                <h3 className={classes.text}>
                  {lang === Lang.zh ? (
                    homePageScript.get(`section8Pork1Text1_${lang}`)
                  ) : (
                    <>
                      <div>{homePageScript.get(`section8Pork1Text1_${lang}`)}</div>
                      <div>{homePageScript.get(`section8Pork1Text2_${lang}`)}</div>
                    </>
                  )}
                </h3>
                <img loading="lazy" src={require('../images/page1/section8/Food_01.jpg')} width={250} alt={''} />
              </div>
              <div style={{ width: 50 }}></div>
              <div className={classes.column}>
                <h3 className={classes.text}>
                  {lang === Lang.zh ? (
                    homePageScript.get(`section8Pork2Text1_${lang}`)
                  ) : (
                    <>
                      <div
                        style={{
                          height: 80,
                          textAlign: 'center',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {homePageScript.get(`section8Pork2Text1_${lang}`)}
                      </div>
                    </>
                  )}
                </h3>
                <img loading="lazy" src={require('../images/page1/section8/Food_02.jpg')} width={250} alt={''} />
              </div>
              <div style={{ width: 50 }}></div>
              <div className={classes.column}>
                <h3 className={classes.text}>
                  {lang === Lang.zh ? (
                    homePageScript.get(`section8Pork3Text1_${lang}`)
                  ) : (
                    <>
                      <div>{homePageScript.get(`section8Pork3Text1_${lang}`)}</div>
                      <div>{homePageScript.get(`section8Pork3Text2_${lang}`)}</div>
                    </>
                  )}
                </h3>
                <img loading="lazy" src={require('../images/page1/section8/Food_03.jpg')} width={250} alt={''} />
              </div>
              <div style={{ width: 50 }}></div>
              <div className={classes.column}>
                <h3 className={classes.text}>
                  {lang === Lang.zh ? (
                    homePageScript.get(`section8Pork4Text1_${lang}`)
                  ) : (
                    <>
                      <div>{homePageScript.get(`section8Pork4Text1_${lang}`)}</div>
                      {/* <div>{homePageScript.get(`section8Pork4Text2_${lang}`)}</div> */}
                    </>
                  )}
                </h3>
                <img
                  loading="lazy"
                  src={require('../images/page1/section8/Food_04.jpg')}
                  width={253}
                  height={250}
                  style={{ objectFit: 'contain' }}
                  alt={''}
                />
              </div>
            </div>
            <div
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 250 }}
            >
              <Link to={lang === Lang.zh ? '/menu_zh' : '/menu_en'} onClick={() => window.scrollTo(0, 0)}>
                <img loading="lazy" src={imgSection8MoreBtn} width={lang === Lang.zh ? 350 : 200} alt={''} />
              </Link>
            </div>
          </div>
        </div>
        {/* section 9 */}
        <section ref={refSection5}>
          <div id={classes.headerSection9}>
            <img
              loading="lazy"
              src={require('../images/page1/section9/background.png')}
              alt={'background'}
              style={{
                position: 'absolute',
                width: '100vw',
                left: 0,
                zIndex: -1,
              }}
            />
            <div className={classes.absoluteWrap}>
              <img loading="lazy" src={imgSection9Header} height={104.69} alt={''} />
            </div>
          </div>
        </section>
        {/* section 10 */}
        <div id={classes.headerSection10}>
          <div className={classes.base}>
            <div className={classes.absoluteWrap}>
              <div className={classes.row} style={{ marginTop: 30 }}>
                <div className={classes.column} style={{ marginTop: 0 }}>
                  <div>
                    <img
                      loading="lazy"
                      src={require('../images/page1/section10/Beef_01.jpg')}
                      width={300}
                      className={classes.img}
                      alt={''}
                    />
                    <h2 className={classes.imgText}>
                      {homePageScript.get(`section10Pork1Text1_${lang}`)}
                      <br />
                      {homePageScript.get(`section10Pork1Text2_${lang}`)}
                      {/* <br />
                      {homePageScript.get(`section10Pork1Text3_${lang}`)} */}
                    </h2>
                  </div>
                </div>
                <div style={{ width: 35 }} />
                <div className={classes.column} style={{ marginTop: 0 }}>
                  <div>
                    <img
                      loading="lazy"
                      src={require('../images/page1/section10/Beef_02.jpg')}
                      width={300}
                      className={classes.img}
                      style={{ zoom: 1.15 }}
                      alt={''}
                    />
                    <h2 className={classes.imgText}>
                      {lang === Lang.zh ? (
                        <></>
                      ) : (
                        <>
                          {homePageScript.get(`section10Pork2Text1_${lang}`)}
                          <br />
                        </>
                      )}

                      {homePageScript.get(`section10Pork2Text2_${lang}`)}
                      <br />
                      {homePageScript.get(`section10Pork2Text3_${lang}`)}
                    </h2>
                  </div>
                </div>
                <div style={{ width: 35 }} />
                <div className={classes.column} style={{ marginTop: 0 }}>
                  <div>
                    <img
                      loading="lazy"
                      src={require('../images/page1/section10/Beef_03.jpg')}
                      width={300}
                      className={classes.img}
                      // style={{ zoom: 1 }}
                      alt={''}
                    />
                    <h2 className={classes.imgText}>
                      {/* {homePageScript.get(`section10Pork3Text1_${lang}`)} */}
                      {homePageScript.get(`section10Pork3Text2_${lang}`)}
                      {/* <br /> */}
                      <br />
                      {homePageScript.get(`section10Pork3Text3_${lang}`)}
                    </h2>
                  </div>
                </div>
              </div>
              {lang === Lang.en ? (
                <></>
              ) : (
                <>
                  <div
                    className={classes.row}
                    style={{
                      marginTop: 30,
                      height: 200,
                      alignItems: 'center',
                    }}
                  >
                    <img
                      loading="lazy"
                      src={require('../images/page1/section10/background.png')}
                      width={'100%'}
                      height={200}
                      style={{
                        zIndex: -1,
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        marginBottom: 0,
                      }}
                      alt={''}
                    />
                    <div className={classes.column} style={{ height: 'auto', alignItems: 'flex-start' }}>
                      <div className={classes.text} style={{ color: '#FFFFFF', lineHeight: 2 }}>
                        {homePageScript.get(`section10Pork1Body_${lang}`)}
                      </div>
                    </div>
                    <div style={{ width: 35 }} />

                    <div className={classes.column} style={{ height: 'auto', alignItems: 'flex-start' }}>
                      <div className={classes.text} style={{ lineHeight: 2 }}>
                        {homePageScript.get(`section10Pork2Body_${lang}`)}
                      </div>
                    </div>
                    <div style={{ width: 35 }} />

                    <div className={classes.column} style={{ height: 'auto', alignItems: 'flex-start' }}>
                      <div className={classes.text} style={{ lineHeight: 2 }}>
                        {homePageScript.get(`section10Pork3Body_${lang}`)}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* section 11 */}
        <section ref={refSection6}>
          <div id={classes.headerSection11} style={{ height: lang === Lang.zh ? 550 : 700 }}>
            <div className={classes.absoluteWrap}>
              <div
                style={{ height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}
              >
                <h2 className={classes.header}>{homePageScript.get(`section11Header_${lang}`)}</h2>
              </div>
              <div className={classes.row}>
                <div className={classes.column}>
                  <div>
                    {homePageScript.get(`section11Text1_${lang}`)}
                    <br />
                    <br />
                    <pre style={{ fontSize: lang === Lang.zh ? 16 : 12 }}>
                      {homePageScript.get(`section11Info_${lang}`)}
                    </pre>
                  </div>
                </div>
                <img
                  loading="lazy"
                  src={require('../images/page1/section11/hk_shop_1.png')}
                  width={300}
                  height={320}
                  style={{ marginRight: 10 }}
                  alt={'hk_shop'}
                />
                <img
                  loading="lazy"
                  src={require('../images/page1/section11/hk_shop_2.png')}
                  width={300}
                  height={320}
                  style={{ marginRight: 10 }}
                  alt={'hk_shop'}
                />
                <img
                  loading="lazy"
                  src={require('../images/page1/section11/hk_shop_3.png')}
                  width={300}
                  height={320}
                  alt={'hk_shop'}
                />
              </div>
            </div>
          </div>
        </section>
        <div className={classes.whiteBanner}>
          <h3>{homePageScript.get(`footerHeader_${lang}`)}</h3>
          <img
            loading="lazy"
            src={require('../images/page1/brown_underline.png')}
            className={classes.underline}
            width={120}
            alt=""
          />
          <div className={classes.flex} style={{ width: lang === Lang.zh ? 1100 : 1100 }}>
            <button
              onClick={() => scrollToSection(refSection1)}
              style={{ width: lang === Lang.zh ? 'inherit' : 'auto' }}
            >
              {homePageScript.get(`menuBtnHomeSection1_${lang}`)}
            </button>
            <button onClick={() => scrollToSection(refSection1)}>
              {homePageScript.get(`menuBtnHomeSection1.5_${lang}`)}
            </button>
            <button onClick={() => scrollToSection(refSection2)}>
              {homePageScript.get(`menuBtnHomeSection2_${lang}`)}
            </button>
            <button onClick={() => scrollToSection(refSection3)}>
              {homePageScript.get(`menuBtnHomeSection3_${lang}`)}
            </button>
            <button onClick={() => scrollToSection(refSection4)}>
              {homePageScript.get(`menuBtnHomeSection4_${lang}`)}
            </button>
            <button onClick={() => scrollToSection(refSection5)}>
              {homePageScript.get(`menuBtnHomeSection5_${lang}`)}
            </button>
            <button onClick={() => scrollToSection(refSection6)}>
              {homePageScript.get(`menuBtnHomeSection6_${lang}`)}
            </button>
          </div>
          <h6>{homePageScript.get(`footerAddress_${lang}`)}</h6>
          <h6>{homePageScript.get(`footerOpening_${lang}`)}</h6>
          <h6>{homePageScript.get(`footerTel_${lang}`)}</h6>
          <h6>miyazaki-nikuhonpo.com.hk</h6>
          <div className={classes.media}>
            <div style={{ width: 10 }} />
            <button
              onClick={() => gotoPlatform(lang === Lang.zh ? OtherPlatform.InlineApp_zh : OtherPlatform.InlineApp_en)}
            >
              <img loading="lazy" src={require(`../images/booking_btn_${lang}.png`)} alt="" width={170} />
            </button>
            <div style={{ width: 10 }} />
            <button>
              <img
                loading="lazy"
                src={require('../images/page2/fb.png')}
                alt=""
                width={30}
                onClick={() => gotoPlatform(OtherPlatform.Facebook)}
              />
            </button>
            <div style={{ width: 10 }} />
            <button>
              <img
                loading="lazy"
                src={require('../images/page2/ig.png')}
                alt=""
                width={30}
                onClick={() => gotoPlatform(OtherPlatform.Instagram)}
              />
            </button>
            <div style={{ width: 10 }} />
            <button>
              <img
                src={require('../images/whatsapp.png')}
                alt=""
                width={30}
                onClick={() => gotoPlatform(OtherPlatform.Whatsapp)}
              />
            </button>
          </div>
          <div className={classes.contact}>
            <h5>網址：miyazaki-nikuhonpo.com.hk</h5>
            <h5>電郵： info@hatsubara.com.hk</h5>
          </div>
        </div>
        <div style={{ height: '100px' }}></div>
      </div>
    </div>
  );
};

export default HomePage;

const homePageScript: Map<string, string> = new Map([
  ['menuBtnHomeSection1_zh', '首頁'],
  ['menuBtnHomeSection1_en', 'Top'],
  ['menuBtnHomeSection1.5_zh', '宮崎肉本舖'],
  ['menuBtnHomeSection1.5_en', 'Miyazaki Niku Honpo'],
  ['menuBtnHomeSection2_zh', '桜花牛.桜花牧場'],
  ['menuBtnHomeSection2_en', 'Ouka Farm．Ouka Beef'],
  ['menuBtnHomeSection3_zh', '一頭牛燒肉'],
  ['menuBtnHomeSection3_en', 'Nose to Tail Yakiniku'],
  ['menuBtnHomeSection4_zh', '燒肉放題'],
  ['menuBtnHomeSection4_en', 'Set．All-you-can-eat'],
  ['menuBtnHomeSection5_zh', '稀有部位'],
  ['menuBtnHomeSection5_en', 'Rare Cuts'],
  ['menuBtnHomeSection6_zh', '有關我們'],
  ['menuBtnHomeSection6_en', 'Contact Us'],
  [
    'section1Text_zh',
    '結合日本本店及姊妹店的精髓，宮崎肉本舖(香港店)主打頂級A5桜花牛燒肉放題。店舖每月從自家桜花農場，以「一頭牛」採購方式，獨家引入得獎無數的A5桜花牛，讓食客可由赤身至霜降，一次嘗遍10種部位，重新認識A5和牛。店內亦供應各種稀有部位，滿足一眾刁鑽的牛迷。',
  ],
  [
    'section1Text_en',
    'Combining the essence of both its branch and sister brand, Miyazaki Niku Honpo (Hong Kong) presents premium all-you-can-eat A5 Ouka Beef yakiniku sets to meat connoisseurs. The restaurant exclusively imports full cattle including some of the rarest cuts, from the award-winning Ouka Farm every month. Savour 10 parts of Ouka Beef at once, rediscovering flavours of A5 wagyu. Apart from quality beef, enjoy different Japanese ingredients guaranteed to delight your palate.',
  ],
  [
    'section1Text_2_zh',
    '除上等A5和牛之外，放題亦供應和牛內臟、黑豚、雞肉、甲羅燒、自家製和牛餃子等超過60款食物選擇，用上不少日本食材，致力帶來最地道的口味。',
  ],
  ['section2Header_zh', '桜花牛/桜花牧場'],
  ['section2Header_en', 'Ouka Wagyu / Ouka Farm'],
  ['section2Header2_zh', '桜花牧場，一生懸命傳承三代'],
  ['section2Header2_en', 'Ouka Farm - A Legacy of Three Generations'],
  [
    'section2Text_zh',
    '被稱為「肉の街」的宮崎縣都城市（Miyakonojo）是全日本飼養和牛數量最多的地方，當地養殖和牛技術冠絕全國。來自這個和牛聖地的桜花牧場，走貴精不貴多精品路線，為了妥善照顧每頭桜花牛，牧場每年只限量出產約100頭桜花牛，以往只作內銷，相當矜貴。牧場傳承三代，靠著祖父那兩頭黑毛和牛起家，累積經驗廿多年，三代人一生懸命的努力下，以心血培育的桜花牛得獎無數。',
  ],
  [
    'section2Text_en',
    "Miyakonojo of Miyazaki Prefecture is known as the 'meat town' and has the largest number of Wagyu cattle in Japan and has some of the best Wagyu cattle breeding techniques in the country. In order to ensure the best quality of their cattle, the farm only produces about 100 Ouka wagyu cattle per year,  used to be sold only for domestic consumption. While the farm has been handed down for three generations, their wagyu business traces back to the grandfather who started off with only two black Kuroge Wagyu cattle, and developed this award-winning breed with 20 years of experience and dedication of the family.",
  ],
  ['section2Header3_zh', '桜花牛，系出名門宮崎牛'],
  ['section2Header3_en', 'Ouka Wagyu - A Heritage of the Legendary Miyazaki Breed'],
  [
    'section2Text2_zh',
    '牧場由繁殖、飼育到屠宰都不假手於他人，系出名門的桜花牛經悉專人心照顧，飼育900日儲脂肪才送上市場。為了讓牛隻健康成長，桜花牧場有獨家門飼育方法及飼料混合比例，從子牛時已開始用中藥和曲霉改善牛隻的肝臟和腸道環境，增強其自身免疫力。由已發酵甘蔗渣及菠蘿渣等纖維製成秘製的天然飼料，蘊含豐富的植物性Omega-3脂肪酸出。飲用水來自霧島山脈地下水，經過火山岩層自然過濾去除雜質，含豐富的礦物質天然純淨。比其他產區A5級數的和牛，桜花牛色澤紅潤，肉質細緻綿密，其霜降油花特別均勻幼細，脂肪熔點與人體體溫相若，入口即溶的感覺更強烈，餘香久久不散。',
  ],
  [
    'section2Text2_en',
    'The Ouka breed was developed in Miyazaki Prefecture exclusively by the family since the grandfather’s generation from breeding, raising to slaughter. All Ouka cattle are raised with extra care for a minimum of 900 days before they are sold to the market. The cattle are brought up with a diet comprising Chinese herbs and Aspergillus to strengthen their liver, intestinal environment and immune system; whereas their original natural feeds are made from Omega-3-rich fermented sugar cane bagasse and pineapple pomace, which are specially designed to elevate the taste and aroma of the meat. The groundwater used in the farm is also naturally filtered through volcanic rock layers and enriched with minerals in the soil of Kirishima Mountains. Compared to other A5 Wagyu beef from other regions, Ouka beef is characterized by its lusciously red color and refined marbling. The low melting point of the beef fat similar to human body temperature offers a delicate melt-in-your-mouth texture and a lingering aftertaste that is hard to resist.',
  ],
  ['section2PreLeft1_zh', '── 桜花牧場歷史──'],
  ['section2PreLeft1_en', 'History'],
  ['section2PreLeft2_zh', '平成15年(2003年)乙守畜產創業'],
  ['section2PreLeft2_en', '2003 - Establishment of Otomori Livestock Products'],
  ['section2PreLeft3_zh', '平成22年(2010年)桜花牧場設立'],
  ['section2PreLeft3_en', '2010 - Establishment of Ouka Farm'],
  ['section3TipTextHeader_en', '── Yakiniku Tips ─'],
  ['section3TipTextHeader_zh', '── 燒肉小貼士 ─'],
  ['section3TipText1_en', '1. Make sure the gridiron is hot before placing the meat on the grill.'],
  ['section3TipText1_zh', '1. 開火約5分鐘待烤網熱透，才放肉。'],
  [
    'section3TipText2_en',
    '2. Do not place the meat right above the fire, especially for meat marinated in sauces or it will be burnt easily.',
  ],
  ['section3TipText2_zh', '2. 建議將肉擺放在爐邊位置，尤其是蘸滿醬汁的肉類，放中心的話很容易被猛火燒焦。'],
  [
    'section3TipText3_en',
    '3. For thick cuts such as sirloin, grill one side until medium before turning over; thin cuts can be enjoyed once they are browned.',
  ],
  ['section3TipText3_zh', '3. 牛肉簡單燒到一邊變色，反轉約十秒即可食用。'],
  [
    'section3TipText4_en',
    '4. For fat-rich offals such as beef intestine, crisp the skin with a low heat before turning over to grill the fatty side.',
  ],
  ['section3TipText4_zh', '4. 牛腸等具豐腴油脂的內臟部位，可用放錫紙盤加熱，其後再放爐邊燒至微焦。'],
  [
    'section3CowSectionLeft_en',
    'Sirloin / サーロイン\nSpencer Roll / リブロース\nBottom Round / 外モモ\nTop Round / 内モモ\nChuck / ウデ\nChuck Eye Roll / 肩ロース\nChuck cap/ 肩カブリ\nKnuckle / シンタマ\nShort Plate / 外バラ\nShort Rib / カルビ\n*Chuck Tender / トウガラシ\n*Tenderloin / ヒレ\n*Chuck Rib / 三角バラ\n*Top Sirloin / ランイチ\n*Rump / ランプ\n*Rare Cuts',
  ],
  [
    'section3CowSectionLeft_zh',
    '西冷 / サーロイン\n肉眼 / リブロース\n外後腿肉 / 外モモ\n內後腿肉 / 内モモ\n前腿肉 / ウデ\n肩里肌 / 肩ロース\n肩口肉 / 肩カブリ\n和尚頭 / シンタマ\n五花腩 / 外バラ\n牛肋肉 / カルビ\n*辣椒肉 /トウガラシ\n*牛柳 / ヒレ\n*三角腩 / 三角バラ\n*牛臀肉 / ランイチ\n*上後腰脊肉 / ランプ\n*稀有部位*',
  ],
  [
    'section3CowSectionRight_en',
    'Offals\n*Skirt Steak / ハラミ\n*Cheek / ツラミ\nTongue / タン\nHeart / ハツ\nLiver / レバー\nBook Tripe / センマイ\nReed Tripe / ギアラ\nSmall Intestine / マルチョウ\nLarge Intestine / シマチョウ',
  ],
  [
    'section3CowSectionRight_zh',
    '內臟\n牛心 / ハツ\n牛肝 / レバー\n牛柏葉 / センマイ\n牛沙瓜 / ギアラ\n丸腸 / マルチョウ\n牛腸 / シマチョウ\n*封門柳 / ハラミ\n*面頰肉 / ツラミ\n牛舌 / タン\n',
  ],
  ['section5Pork1Text1_en', 'Miyazaki A5 Ouka Beef'],
  ['section5Pork1Text1_zh', '宮崎A5桜花牛'],
  ['section5Pork1Text2_en', 'Supreme Set'],
  ['section5Pork1Text2_zh', '極上燒肉放題'],
  ['section5Pork1Text3_en', '$998 Each'],
  ['section5Pork1Text3_zh', '$998/位'],
  ['section5Pork2Text1_en', 'A5 Miyazaki Beef'],
  ['section5Pork2Text1_zh', 'A5宮崎牛'],
  ['section5Pork2Text2_en', 'Premium Set'],
  ['section5Pork2Text2_zh', '嚴選燒肉放題'],
  ['section5Pork2Text3_en', '$898 Each'],
  ['section5Pork2Text3_zh', '$898/位'],
  ['section5Pork3Text1_en', 'A5 Miyazaki Beef'],
  ['section5Pork3Text1_zh', 'A5宮崎牛特上燒肉放題'],
  ['section5Pork3Text2_en', 'Set'],
  ['section5Pork3Text3_en', '$698 Each'],
  ['section5Pork3Text3_zh', '$698/位'],
  ['section5Pork4Text1_en', 'Kyushu A5 Wagyu'],
  ['section5Pork4Text1_zh', '九州A5和牛套餐'],
  ['section5Pork4Text2_en', 'Beef Set'],
  ['section6remark1_en', '*Photo is Set for 4'],
  ['section6remark1_zh', '*圖中為四人份量'],
  ['section6remark2_en', 'and for reference only'],
  ['section6remark2_zh', '*圖片只供參考'],
  ['section6Pork1Text1_en', '10 types of selected Ouka Beef'],
  ['section6Pork1Text1_zh', '10款桜花牛拼盤'],
  ['section6Pork1Text2_en', '6 types of all-you-can-eat Ouka Beef'],
  ['section6Pork1Text2_zh', '6款無限追加A5桜花牛'],
  ['section6Pork1Text3_en', 'more than 60 dishes all-you-can-eat'],
  ['section6Pork1Text3_zh', '放題單品60款'],
  ['section6Pork2Text1_en', '8 types of selected Ouka Beef'],
  ['section6Pork2Text1_zh', '8款桜花牛拼盤'],
  ['section6Pork2Text2_en', '6 types of all-you-can-eat Ouka Beef'],
  ['section6Pork2Text2_zh', '6款無限追加A5桜花牛'],
  ['section6Pork2Text3_en', 'more than 60 dishes all-you-can-eat'],
  ['section6Pork2Text3_zh', '放題單品60款以上'],
  ['section6Pork3Text1_en', '9 types of selected Kyushu Beef'],
  ['section6Pork3Text1_zh', '＋ 60款以上放題單品'],
  ['section6Pork3Text2_en', 'more than 60 dishes all-you-can-eat'],
  ['section6Pork4Text1_en', '6 types of selected Kyushu Beef'],
  ['section6Pork4Text1_zh', '＋ 60款以上放題單品'],
  ['section6Pork4Text2_en', 'more than 60 dishes all-you-can-eat'],
  ['section8Header_zh', '──精選放題單品──'],
  ['section8Header_en', '──Selected Menu──'],
  ['section8Pork1Text1_zh', '和牛內臟'],
  ['section8Pork1Text1_en', 'Japanese'],
  ['section8Pork1Text2_en', 'Wagyu Offals'],
  ['section8Pork2Text1_zh', '日本雞肉'],
  ['section8Pork2Text1_en', 'Japanese Chicken'],
  ['section8Pork3Text1_zh', '日本黑豚'],
  ['section8Pork3Text1_en', 'Japanese '],
  ['section8Pork3Text2_en', 'Kurobuta Pork'],
  ['section8Pork4Text1_zh', '日本直送海產、野菜'],
  ['section8Pork4Text1_en', 'Japanese Jet-fresh Seafood'],
  ['section8Pork4Text2_en', '& Vegetables'],
  ['section10Pork1Text1_en', 'Tenderloin'],
  ['section10Pork1Text1_zh', '牛柳'],
  ['section10Pork1Text2_en', '$198/ 40-45g'],
  ['section10Pork1Text2_zh', '$198/ 40-45g'],
  ['section10Pork1Text3_en', '$380'],
  ['section10Pork1Text3_zh', '$380'],
  ['section10Pork2Text1_en', 'Chuck Tender'],
  ['section10Pork2Text1_zh', '辣椒肉'],
  ['section10Pork2Text2_en', 'Tongue Platter'],
  ['section10Pork2Text2_zh', '辣椒肉'],
  ['section10Pork2Text3_en', '$198/ 40-45g'],
  ['section10Pork2Text3_zh', '$198/ 40-45g'],
  ['section10Pork3Text1_zh', '和尚頭'],
  ['section10Pork3Text2_en', 'Knuckle'],
  ['section10Pork3Text2_zh', '和尚頭'],
  ['section10Pork3Text3_en', '$138/ 40-45g'],
  ['section10Pork3Text3_zh', '$138/ 40-45g'],
  [
    'section10Pork1Body_en',
    'Chef’s bespoke selection of some of the rarest cuts of beef only possible thanks to the full cattle sourcing approach of the restaurant. Limited servings per day and only available as an upgrade for “Miyazaki A5 Ouka Beef Supreme Set ($988)” and “A5 Miyazaki Beef Premium Set ($898)” orders.',
  ],
  ['section10Pork1Body_zh', '和牛最矜貴、最軟嫩的部份，遍布精緻幼細的油花，入口是剛剛好的美妙味道。'],
  [
    'section10Pork2Body_en',
    'In line with our insistence on using 100% premium Japanese ingredients, only Kagoshima A5 Wagyu beef tongue will be served with a combination of thick-cut beef tongue and a thinly sliced beef tongue with salt and scallion, offering a variety of flavours and textures of this delicacy.',
  ],
  ['section10Pork2Body_zh', '近肩胛骨的赤肉部位，因狀似辣椒得名，肉味香濃，份量極為 稀少，只佔一頭牛的1%。'],
  [
    'section10Pork3Body_en',
    'Asahi Super Dry is uniquely designed to be dry and crisp with a quick, clean finish. The creamy head locks in the carbonation, keeping the beer crisp and clean. Only one button, with the perfect Super Dry serve, every time.',
  ],
  ['section10Pork3Body_zh', '取自後腿的根部，因渾圓光滑而得名，肉味純正，且質地軟嫩。'],
  ['section11Header_zh', '宮崎肉本舖（香港店）'],
  ['section11Header_en', 'Miyazaki Niku Honpo（Hong Kong Branch）'],
  [
    'section11Text1_en',
    'No. 1 Overseas Store is located in the heart of Causeway Bay, a Ginza-style commercial building "Sugar+", with a view of the Victoria Harbour. The Japanese-style wooden atmosphere is matched with tatami seating, and several semi-open room are specially set up to enjoy the Japanese-style Wagyu beef dinner while enjoying the night view.',
  ],
  [
    'section11Text1_zh',
    '香港店選址銅鑼灣中心地帶，座落銀座式商廈Sugar+，坐擁維園景致。客人可在繁華的夜景之下，盡情享受日式燒肉滋味。店內特設半開放式及日式榻榻米包廂，適合各種聚會需求。',
  ],
  ['section11Info_zh', '座位： 約80席\n半開放式： 7室\n4-12人房間： 4室\n開瓶費：$200\n切餅費：$100'],
  [
    'section11Info_en',
    'Address：6/F, Sugar+, 25-36 Sugar \nStreet, Causeway Bay\nOpening Hours：12nn-3pm (2:30 L.O.) \n/ 6pm-10pm (9:30 L.O.)\nReservations：＋852 3568 5330\nWhatsApp：\nWebsite：miyazaki-nikuhonpo.com.hk\nE-mail： info@hatsubara.com.hk\nCorkage Fee：$200\nCake-cutting Fee：$100',
  ],
  ['footerHeader_zh', '關於我們'],
  ['footerHeader_en', 'ABOUT'],
  ['footerAddress_zh', '地址：香港銅鑼灣糖街25-31號Sugar＋6樓'],
  ['footerAddress_en', 'Address: 6/F, Sugar+, 25-36 Sugar Street, Causeway Bay'],
  ['footerOpening_zh', '營業時間:星期一至五: 5pm-12am\n星期六、日及公眾假期: 12pm-3pm, 5pm-12am'],
  ['footerOpening_en', 'Monday to Friday: 5pm-12am\nSaturday, Sunday and public holidays: 12pm-3pm, 5pm-12am'],
  ['footerTel_zh', '查詢/訂座:3568 5330 (WhatsApp:6880 8908)'],
  ['footerTel_en', '＋852 3568 5330'],
]);
