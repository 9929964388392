export enum OtherPlatform {
  Facebook,
  Instagram,
  InlineApp_zh,
  InlineApp_en,
  Member_mobile_Android,
  Member_mobile_iOS,
  Member_website,
  Whatsapp,
}

export const gotoPlatform = (platform: OtherPlatform) => {
  switch (platform) {
    case OtherPlatform.Facebook:
      window.open('https://www.facebook.com/Miyazaki.nikuhonpo');
      break;
    case OtherPlatform.Instagram:
      window.open('https://www.instagram.com/miyazaki.nikuhonpohk/');
      break;
    case OtherPlatform.InlineApp_zh:
      window.open('https://inline.app/booking/miyazaki/nikuhonpohk?language=zh-hk');
      break;
    case OtherPlatform.InlineApp_en:
      window.open('https://inline.app/booking/miyazaki/nikuhonpohk?language=en');
      break;
    case OtherPlatform.Member_mobile_iOS:
      window.open('https://apps.apple.com/hk/app/storellet-membership-reward/id936066838');
      break;
    case OtherPlatform.Member_mobile_Android:
      window.open('https://play.google.com/store/apps/details?id=com.storellet');
      break;
    case OtherPlatform.Member_website:
      // window.open('https://www.facebook.com/Miyazaki.nikuhonpo');
      window.open('https://apps.apple.com/hk/app/storellet-membership-reward/id936066838');
      break;
    case OtherPlatform.Whatsapp:
      window.open('https://wa.me/85268808908');
      break;
  }
};

export enum Lang {
  zh = 'zh',
  en = 'en',
}

export const changeWebsiteTitle = (lang: Lang) => {
  document.title = lang === Lang.zh ? '宮崎肉本舖（香港店）' : 'Miyazaki Niku Honpo (Hong Kong)';
};
