import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import './_reset.scss';
import MenuPage from './pages/MenuPage';
import HomePage from './pages/HomePage';
import HomePageMobile from './pages/HomePageMobile';
import { Lang } from './utils/util';
import ReactGA from 'react-ga';
  
const TRACKING_ID = "UA-240946104-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  const [width, setWidth] = useState<number>(window.innerWidth);
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const isMobile = width <= 768;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className='App'>
      <header>{/* <p>Coming Soon</p> */}</header>
      <Router>
        <Routes>
          {/* Chinese route */}
          <Route path='/menu_zh' element={<MenuPage language={Lang.zh} />} />
          <Route
            path='/'
            element={isMobile ? <HomePageMobile language={Lang.zh} /> : <HomePage language={Lang.zh} />}
          />
          {/* Eng route */}
          <Route path='/menu_en' element={<MenuPage language={Lang.en} />} />
          <Route
            path='/en'
            element={isMobile ? <HomePageMobile language={Lang.en} /> : <HomePage language={Lang.en} />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
