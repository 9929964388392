import React from 'react';
import MUIDrawer from '@material-ui/core/Drawer/Drawer';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.11)',
    // backdropFilter: 'blur(4px)',
    pointerEvents: 'initial',
    transition: 'background-color 300ms, backdrop-filter 300ms',
  },
});

interface Props {
  isShow?: boolean;
  onClose?: ((event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void) | undefined;
}

const Drawer: React.FC<Props> = (props) => {
  const styles = useStyles();
  return (
    <MUIDrawer anchor={'right'} open={props.isShow} onClose={props.onClose} className={styles.root} elevation={0}>
      {props.children}
    </MUIDrawer>
  );
};

export default Drawer;
