import React, { useEffect, useRef, useState } from 'react';
import Drawer from '../components/Drawer/Drawer';
import { changeWebsiteTitle, gotoPlatform, Lang, OtherPlatform } from '../utils/util';
import classes from './HomePageMobile.module.scss';
import { isAndroid, isIOS } from 'react-device-detect';

interface Props {
  language: Lang;
}

const HomePageMobile: React.FC<Props> = (props) => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  useEffect(() => {
    document.title = props.language === Lang.zh ? '宮崎肉本舖（香港店）' : 'Miyazaki Niku Honpo (Hong Kong)';
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const isSmallerMobile = width <= 400;

  const [isNavMenuOpen, setisNavMenuOpen] = useState(false);

  const refSection1 = useRef<HTMLElement>(null);
  const refSection2 = useRef<HTMLElement>(null);
  const refSection3 = useRef<HTMLElement>(null);
  const refSection4 = useRef<HTMLElement>(null);
  const refSection5 = useRef<HTMLElement>(null);
  const refSection6 = useRef<HTMLElement>(null);

  const scrollToSection = (ref: React.RefObject<HTMLElement>) => {
    const XXXEl = ref.current;
    if (!XXXEl) return;

    XXXEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
    setisNavMenuOpen(false);
  };

  const [lang, setLang] = useState(props.language);

  const TextMapping = {
    [Lang.zh]: {
      nav1: '首頁',
      nav2: '桜花牛.桜花牧場',
      nav3: '一頭牛燒肉',
      nav4: '燒肉放題',
      nav5: '稀有部位',
      nav6: '有關我們',

      ms1t1: '2015年，在九州宮崎縣開設了首家「宮崎肉本舖」燒肉放題專門店。',
      ms1t2: '2022年，宮崎肉本舖( 香港店)亞洲首間海外分店正式登陸香港。',
      ms1t3:
        '結合日本本店及姊妹店的精髓，宮崎肉本舖(香港店)主打頂級A5桜花牛燒肉放題。店舖每月從自家桜花農場，以「一頭牛」採購方式，獨家引入得獎無數的A5桜花牛，讓食客可由赤身至霜降，一次嘗遍10種部位，重新認識A5和牛。店內亦供應各種稀有部位，滿足一眾刁鑽的牛迷。除上等A5和牛之外，放題亦供應和牛內臟、黑豚、地雞、甲羅燒、自家製和牛餃子等超過60款食物選擇，全用上日本產時令食材，致力帶來最地道的口味。',

      ms2t1: '桜花牛/桜花牧場',
      ms2t2: '桜花牧場，一生懸命傳承三代',
      ms2t3:
        '被稱為「肉の街」的宮崎縣都城市(Miyakonojo)是全日本飼養和牛數量最多的地方，當地養殖和牛技術冠絕全國。來自這個和牛聖地的桜花牧場，走貴精不貴多精品路線，為了妥善照顧每頭桜花牛，牧場每年只限量出產約100頭桜花牛，以往只作內銷，相當矜貴。牧場傳承三代，靠著祖父那兩頭黑毛和牛起家，累積經驗廿多年，三代人一生懸命的努力下，以心血培育的桜花牛得獎無數。',
      ms2t4: '桜花牛，系出名門宮崎牛',
      ms2t5:
        '牧場由繁殖、飼育到屠宰都不假手於他人，系出名門的桜花牛經悉專人心照顧，飼育900日儲脂肪才送上市場。為了讓牛隻健康成長，桜花牧場有獨家門飼育方法及飼料混合比例，從子牛時已開始用中藥和曲霉改善牛隻的肝臟和腸道環境，增強其自身免疫力。由已發酵甘蔗渣及菠蘿渣等纖維製成秘製的天然飼料，蘊含豐富的植物性Omega-3脂肪酸出。飲用水來自霧島山脈地下水，經過火山岩層自然過濾去除雜質，含豐富的礦物質天然純淨。比其他產區A5級數的和牛，桜花牛色澤紅潤，肉質細緻綿密，其霜降油花特別均勻幼細，脂肪熔點與人體體溫相若，入口即溶的感覺更強烈，餘香久久不散。',

      section3T1: 'section3_t1_zh',
      ms3t2:
        '西冷 / サーロイン\n肉眼 / リブロース\n外後腿肉 / 外モモ\n內後腿肉 / 内モモ\n前腿肉 / ウデ\n肩里肌 / 肩ロース\n肩口肉 / 肩カブリ\n和尚頭 / シンタマ\n五花腩 / 外バラ\n牛肋肉 / カルビ\n*辣椒肉 /トウガラシ\n*牛柳 / ヒレ\n*三角腩 / 三角バラ\n*牛臀肉 / ランイチ\n*上後腰脊肉 / ランプ\n*稀有部位*',
      ms3t3:
        '內臟\n牛心 / ハツ\n牛肝 / レバー\n牛柏葉 / センマイ\n牛沙瓜 / ギアラ\n丸腸 / マルチョウ\n牛腸 / シマチョウ\n*封門柳 / ハラミ\n*面頰肉 / ツラミ\n牛舌 / タン\n',

      ms3t4: '── 燒肉小貼士 ─',
      ms3t5: '1. 開火約5分鐘待烤網熱透，才放肉。',
      ms3t6: '2. 建議將肉擺放在爐邊位置，尤其是蘸滿醬汁的肉類，放中心的話很容易被猛火燒焦。',
      ms3t7: '3. 牛肉簡單燒到一邊變色，反轉約十秒即可食用。',
      ms3t8: '4. 牛腸等具豐腴油脂的內臟部位，可用放錫紙盤加熱，其後再放爐邊燒至微焦。',

      ms5Header: 'text',

      ms6t1: '*圖中為四人份量',
      ms6t2: '*圖片只供參考',

      ms9Header: 'header',

      ms10p1t1: '牛柳',
      ms10p1t2: '$198/ 40-45g',
      ms10p2t1: '辣椒肉',
      ms10p2t2: '辣椒肉',
      ms10p2t3: '$198/ 40-45g',
      ms10p3t2: '和尚頭',
      ms10p3t3: '$138/ 40-45g',
      ms10p1b1: '和牛最矜貴、最軟嫩的部份，遍布精緻幼細的油花，入口是剛剛好的美妙味道。',

      ms10p2b1: '近肩胛骨的赤肉部位，因狀似辣椒得名，肉味香濃，份量極為 稀少，只佔一頭牛的1%。',
      ms10p3b1: '取自後腿的根部，因渾圓光滑而得名，肉味純正，且質地軟嫩。',

      ms11t1: '宮崎肉本舖（香港店）',
      ms11t2:
        '香港店選址銅鑼灣中心地帶，座落銀座式商廈Sugar+，坐擁維園景致。客人可在繁華的夜景之下，盡情享受日式燒肉滋味。店內特設半開放式及日式榻榻米包廂，適合各種聚會需求。',
      ms11t3: '座位： 約80席\n半開放式： 7室\n4-12人房間： 4室\n開瓶費：$200\n切餅費：$100',

      s1t1: '─ 宮崎A5桜花牛極上燒肉放題 ─',
      s1t2: '每位 $998',
      s1t3: '店舖獨家進口的A5桜花牛，每年只出產數十隻，相當矜貴。經過日本職人的細心培育，每隻牛的油花分布標準BMS均達10級，又因食用多種天然飼料的關係，肉質份外幼嫩，油脂分布特別幼細，且脂肪溶點與人體體溫相若，故入口油香特別強烈。客人可以由赤身至霜降，口味從輕到重，一次嘗遍10種不同部位，細味日本頂級的宮崎牛。',
      s1t4: '─ 4款 A5桜花牛 ─',
      s1t5_1: '稀有部位和尚頭‧前腿肉',
      s1t5_2: '西冷扒或肉眼扒 (廚師發辦)',
      s1t5_3: '壽喜燒 (附雞蛋一隻)',
      s1t6: '─ 任點 6款 A5桜花牛 ─',
      s1t7: '外後腿肉‧內後腿肉‧肩里肌',
      s1t8: '肩口肉‧五花腩‧牛肋肉',
      s1t9: '─ 60款日本食材放題 ─',
      s1t10: '(全單需惠顧相同套餐 , 另收加一服務費)',

      s2t1: '─ A5宮崎牛嚴選燒肉放題 ─',
      s2t2: '每位 $898',
      s2t3: '和牛拼盤嚴選A5宮崎牛8個最優質的部位,組合出集和牛肉香、油香於一身的好滋味,冰鮮空運到港的桜花牛鮮度滿分,肉汁豐沛,口感嫩滑,套餐還可無限追加宮崎A5桜花牛。',
      s2t4: '─ 1款 A5桜花牛 ─',
      s2t5: '壽喜燒 (附雞蛋一隻)',
      s2t6: '─ 任點 6款 A5宮崎牛 ─',
      s2t7: '外後腿肉‧內後腿肉‧肩里肌',
      s2t8: '肩口肉‧五花腩‧牛肋肉',
      s2t9: '─ 60款日本食材放題 ─',
      s2t10: '(全單需惠顧相同套餐 , 另收加一服務費)',

      s3t1: '─ A5宮崎牛特上燒肉放題 ─',
      s3t2: '每位 $698',
      s3t3: '挑選油花密布又雪白的A5級的特上宮崎牛,集齊 西冷薄片、肉眼薄片、外後腿肉及肩口肉等9個不同部位,各有獨一無二的口感和味道,適合追求油香的和牛控。',
      s3t4: '─ 4款 A5宮崎牛 ─',
      s3t5: '內後腿肉‧肩里肌',
      s3t5_1: '肩口肉‧牛肋肉',
      s3t6: '─ 任點 2款A5宮崎牛 ─',
      s3t6_1: '外後腿肉‧五花腩',
      s3t7: '(全單需惠顧相同套餐 , 另收加一服務費)',
      s3t7_1: '─ 60款日本食材放題 ─',
      s3t7_2: '(不包括海鮮)',

      s4t1: '─ 九州A5和牛套餐 ─',
      s4t2: '每位 $598',
      s4t3: '來自九州的A5和牛色澤鮮嫩紅潤,油脂滿瀉, 和牛入口肥香油潤,肉質鬆化有濃郁的油香,還 可以嘗到牛肝及牛腸兩大內臟名物,燒來特別 惹味香口,嗜重口味者必選。',
      s4t4: '─ 7款九州A5和牛拼盤 ──',
      s4t5: '外後腿肉‧內後腿肉‧肩里肌‧肩口肉 ‧ 五花腩 ‧ 牛肋肉 ‧ 牛 肝 ‧ 牛 腸',
      s4t6: '─ 60款日本食材放題 ─',
      s4t7: '(全單需惠顧相同套餐 , 另收加一服務費)',

      re: '──精選放題單品──',

      re1t1: '和牛內臟',
      re1t2:
        '店舖秉承使用日本食材的理念，因此不惜工本，連帶清洗、醃味等工序，為食客帶來日本國民滋味。每日放題都會供應三款精選內臟，牛迷確實不可錯過。',
      re1List: ['(每日供應三款)', '牛心', '牛肝', '大腸', '丸腸', '牛栢葉', '牛沙瓜', '草肚', '牛肚', '牛肺', '牛尾'],

      re2t1: '日本雞肉',
      re2t2:
        '九州熊本產的「うまかハーブ鶏」(Umaka香草雞)以混合百里香、鼠尾草和迷迭香等的飼料餵飼，雞肉脂肪量含量較低，肉質爽口嫩滑，入口嘗到濃郁雞味之餘，隱隱滲出淡淡香草味。',
      re2List: [
        '雞中翼（鹽燒、山葵）',
        '雞皮（鹽葱、黑七味）',
        '雞腿肉（自家製香草醬、鹽葱、辛口、柚子味噌）',
        '雞頸肉（鹽葱、黑七味、辛口）',
        '日式免治雞肉軟骨棒',
        '雞腎 (黑七味)',
        '雞肝 (黑七味)',
        '燒汁雞心',
        '雞冠 (辛口)',
      ],

      re3t1: '日本黑豚',
      re3t2:
        '含豐富維他命E和均勻脂肪的日本黑豚，肉色粉紅而油脂帶白，柔軟鬆化。明火燒烤可令油脂半溶，逼出香氣，油脂豐腴之餘，帶有獨特的甘味及甜香。',
      re3List: [
        '黑豚五花腩',
        '（柚子味噌、辛口、生薑、黑七味、鹽葱）',
        '黑豚上肩肉',
        '（柚子味噌、辛口、生薑、黑七味、鹽葱）',
        '黑豚頸肉',
        '（柚子味噌、辛口、生薑、黑七味、鹽葱）',
        '日式黑豚芝士腸',
      ],

      items: '──放題單品──',
      itemsList: [
        ['日本直送海產', '辣汁魷魚', '白汁芝士帆立貝', '櫻花蝦蛋黃醬帆立貝', '香草牛油廣島蠔', '北海道一汐赤魚'],
        ['日本直送野菜', '京葱', '甜薯', '南瓜', '白粟米', '青椒仔', '秋葵', '明太子牛油野菌', '野菜盛合'],
        [
          '小食',
          '枝豆',
          '漬物三品',
          '蟹味噌甲羅燒',
          '雞皮餃子',
          '雜錦天婦羅',
          '芝士年糕',
          '吉列廣島蠔',
          '熊本縣產香草雞炸雞',
          '自家製九州和牛黑松露餃子',
          '自家製九州和牛辛口餃子',
        ],
        ['沙律', '宮崎肉本舖和風沙律', '椰菜沙律', '冷豆腐海老沙律', '胡麻豆腐菠菜沙律', '淮山松葉蟹肉沙律'],
        ['米飯', '松葉蟹肉茶漬飯', '海膽蟹肉脆米魚湯泡飯', '味自慢雞肉飯', '山賊汁九州和牛米餅', '天然飯'],
        ['粉麵', '一之藏肋條牛骨湯拉麵', '廣島蠔和牛豆乳湯烏冬', '九州胡麻冷麵', '蕎麥麵', '辣麵'],
        ['甜品', '雪糕 (多款口味)', '3.6 特濃牛乳奶凍 (限量供應)'],
        ['飲品', '乳酸梳打 (多款口味)', '冰茶', '可口可樂', '零糖可口可樂'],
      ],
      footer: '關於我們',
      footer2: '網址：miyazaki-nikuhonpo.com.hk',
      footer3: '電郵： info@hatsubara.com.hk',
      footer4: '地址：香港銅鑼灣糖街25-31號Sugar＋6樓',
      footer5: '營業時間:星期一至五: 5pm-12am\n星期六、日及公眾假期: 12pm-3pm, 5pm-12am',
      footer6: '查詢/訂座:3568 5330 (WhatsApp:6880 8908)',

      bookingBtn: 'booking_btn_zh',
      birthdayBtn: 'menuBirthdayButton_zh',
      menuLogo: 'menuLogo_zh',
      lunchMenuBtn: 'lunch_menu_zh',
    },
    [Lang.en]: {
      nav1: 'Top',
      nav2: 'Ouka Farm.Ouka Beef',
      nav3: 'Nose to Tail Yakiniku',
      nav4: 'Set.All-you-can-eat',
      nav5: 'Rare Cuts',
      nav6: 'Contact Us',

      ms1t1:
        '2015 - The first "Miyazaki Niku Honpo" all-you-can-eat yakiniku restaurant was opened in Miyazaki Prefecture, Kyushu.',
      ms1t2: '2022 - “Miyazaki Niku Honpo” opens its very overseas branch in Hong Kong.',
      ms1t3:
        'Combining the essence of both its branch and sister brand, Miyazaki Niku Honpo (Hong Kong) presents premium all-you-can-eat A5 Ouka Beef yakiniku sets to meat connoisseurs. The restaurant exclusively imports full cattle including some of the rarest cuts, from the award-winning Ouka Farm every month. Savour 10 parts of Ouka Beef at once, rediscovering flavours of A5 wagyu.Apart from quality beef, enjoy 100% best-in-season Japanese ingredients guaranteed to delight your palate.',
      ms2t1: 'Ouka Wagyu / Ouka Farm',
      ms2t2: 'Ouka Farm - A Legacy of Three Generations',
      ms2t3:
        "Miyakonojo of Miyazaki Prefecture is known as the 'meat town' and has the largest number of Wagyu cattle best quality of their cattle, the farm only produces about 100 Ouka wagyu cattle per year, used to be sold only for domestic consumption. While the farm has been handed down for three generations, their wagyu business traces back to the grandfather who started off with only two black Kuroge Wagyu cattle, and developed this",
      ms2t4: 'Ouka Wagyu - A Heritage of the Legendary Miyazaki Breed',
      ms2t5:
        'The Ouka breed was developed in Miyazaki Prefecture exclusively by the family since the grandfather’s generation from breeding, raising to slaughter. All Ouka cattle are raised with extra care for a minimum of 900 days before they are sold to the market. The cattle are brought up with a diet comprising Chinese herbs and Aspergillus to strengthen their liver, intestinal environment and immune system; whereas their original natural feeds are made from Omega-3-rich fermented sugar cane bagasse and pineapple pomace, which are specially designed to elevate the taste and aroma of the meat. The groundwater used in the farm is also naturally filtered through volcanic rock layers and enriched with minerals in the soil of Kirishima Mountains. Compared to other A5 Wagyu beef from other regions, Ouka beef is characterized by its lusciously red color and refined marbling. The low melting point of the beef fat similar to human body temperature offers a delicate melt-in-your-mouth texture and a lingering aftertaste that is hard to resist.',

      section3T1: 'section3_t1_en',
      ms3t2:
        'Sirloin / サーロイン\nSpencer Roll / リブロース\nBottom Round / 外モモ\nTop Round / 内モモ\nChuck / ウデ\nChuck Eye Roll / 肩ロース\nChuck cap/ 肩カブリ\nKnuckle / シンタマ\nShort Plate / 外バラ\nShort Rib / カルビ\n*Chuck Tender / トウガラシ\n*Tenderloin / ヒレ\n*Chuck Rib / 三角バラ\n*Top Sirloin / ランイチ\n*Rump / ランプ\n*Rare Cuts',

      ms3t3:
        'Offals\n*Skirt Steak / ハラミ\n*Cheek / ツラミ\nTongue / タン\nHeart / ハツ\nLiver / レバー\nBook Tripe / センマイ\nReed Tripe / ギアラ\nSmall Intestine / マルチョウ\nLarge Intestine / シマチョウ',

      ms3t4: '── Yakiniku Tips ─',
      ms3t5: '1. Make sure the gridiron is hot before placing the meat on the grill.',
      ms3t6:
        '2. Do not place the meat right above the fire, especially for meat marinated in sauces or it will be burnt easily.',
      ms3t7:
        '3. For thick cuts such as sirloin, grill one side until medium before turning over; thin cuts can be enjoyed once they are browned.',
      ms3t8:
        '4. For fat-rich offals such as beef intestine, crisp the skin with a low heat before turning over to grill the fatty side.',

      ms5Header: 'text_en',

      ms6t1: '*Photo is Set for 4',
      ms6t2: 'and for reference only',

      ms9Header: 'header_en',

      ms10p1t1: 'Tenderloin',
      ms10p1t2: '$198/ 40-45g',
      ms10p2t1: 'Chuck Tender',
      ms10p2t2: 'Chuck Tender',
      ms10p2t3: '$198/ 40-45g',
      ms10p3t1: 'Unlimited',
      ms10p3t2: 'Knuckle',
      ms10p3t3: '$138/ 40-45g',
      ms10p1b1:
        'Chef’s bespoke selection of some of the rarest cuts of beef only possible thanks to the full cattle sourcing approach of the restaurant. Limited servings per day and only available as an upgrade for “Miyazaki A5 Ouka Beef Supreme Set ($988)” and “A5 Miyazaki Beef Premium Set ($898)” orders.',

      ms10p2b1:
        'In line with our insistence on using 100% premium Japanese ingredients, only Kagoshima A5 Wagyu beef tongue will be served with a combination of thick-cut beef tongue and a thinly sliced beef tongue with salt and scallion, offering a variety of flavours and textures of this delicacy.',
      ms10p3b1:
        'Asahi Super Dry is uniquely designed to be dry and crisp with a quick, clean finish. The creamy head locks in the carbonation, keeping the beer crisp and clean. Only one button, with the perfect Super Dry serve, every time.',

      ms11t1: 'Miyazaki Niku Honpo（Hong Kong Branch）',
      ms11t2:
        'No. 1 Overseas Store is located in the heart of Causeway Bay, a Ginza-style commercial building "Sugar+", with a view of the Victoria Harbour. The Japanese-style wooden atmosphere is matched with tatami seating, and several semi-open room are specially set up to enjoy the Japanese-style Wagyu beef dinner while enjoying the night view.',
      ms11t3:
        'Address：6/F, Sugar+, 25-36 Sugar \nStreet, Causeway Bay\nOpening Hours：12nn-3pm (2:30 L.O.) \n/ 6pm-10pm (9:30 L.O.)\nReservations：＋852 3568 5330\nWhatsApp：\nWebsite：miyazaki-nikuhonpo.com.hk\nE-mail： info@hatsubara.com.hk\nCorkage Fee：$200\nCake-cutting Fee：$100',

      s1t1: '─ Miyazaki A5 Ouka Beef Supreme Set ─',
      s1t2: '$998 Each',
      s1t3: 'The ultimate choice for beef connoisseurs - a refined selection of the highest grade Miyazaki A5 Ouka Wagyu with the best cuts in- cluding the signature sirloin, spencer roll, short rib, and rare cuts such as knuckle and the melt-in-your-mouth chuck cap for a heavenly experience.',
      s1t4: '─ Miyazaki A5 Ouka Beef Supreme Platter ─',
      s1t5_1:
        'Sirloin & Spencer Roll / Bottom Round / Top Round / Chuck / Chuck Eye Roll / Chuck cap / Knuckle / Short Plate / Short Rib / Sukiyaki (with Egg)',
      s1t5_2:
        'Sirloin & Spencer Roll / Bottom Round / Top Round / Chuck / Chuck Eye Roll / Chuck cap / Knuckle / Short Plate / Short Rib / Sukiyaki (with Egg)',
      s1t5_3:
        'Sirloin & Spencer Roll / Bottom Round / Top Round / Chuck / Chuck Eye Roll / Chuck cap / Knuckle / Short Plate / Short Rib / Sukiyaki (with Egg)',
      s1t6: '─ 6 Types All you can eat Ouka Beef ─',
      s1t7: 'Bottom Round / Top Round / Chuck Eye Roll',
      s1t8: 'Chuck cap / Short Plate / Short Rib',
      s1t9: '─ more than 60 dishes all-you-can-eat ─',
      s1t10: '(10% Service Charge)',

      s2t1: '─ A5 Miyazaki Beef Premium Set ─',
      s2t2: '$898 Each',
      s2t3: 'This premium A5 Miyazaki beef platter comprises 8 best cuts from the cattle with superior succulence, aroma and flavors, for as much as you desire.',
      s2t4: '─ A5 Miyazaki Beef Premium Platter ─',
      s2t5: 'Bottom Round / Top Round / Chuck Eye Roll / Chuck Cap / Short Plate / Short Rib / Sukiyaki (with Egg)',
      s2t6: '─ 6 Types All you can eat Miyazaki Beef ─',
      s2t7: 'Bottom Round / Top Round / Chuck Eye Roll',
      s2t8: 'Chuck cap / Short Plate / Short Rib',
      s2t9: '─ more than 60 dishes all-you-can-eat ─',
      s2t10: '(10% Service Charge)',

      s3t1: '─ A5 Miyazaki Beef Set ─',
      s3t2: '$698 Each',
      s3t3: 'Perfect for those going after for the rich, savory flavor of wagyu beef fat. Enjoy and compare the contrasting flavors and textures among 9 different cuts of premium A5 Kyushu Kuroge Wagyu including sliced sirloin, spencer roll, bottom round, top round and more.',
      s3t4: '─ A5 Miyazaki Beef Premium Platter ─',
      s3t5: 'Top Round / Chuck Eye Roll',
      s3t5_1: '/ Bottom Round / Short Plate',
      s3t6: '─ 2 Types All you can eat A5 Miyazaki Beef ─',
      s3t6_1: 'Bottom Round / Short Plate',
      s3t7: '(10% Service Charge)',
      s3t7_1: '─ more than 60 dishes all-you-can-eat ─',
      s3t7_2: '(not including seafood)',

      s4t1: '─ Kyushu A5 Wagyu Beef Set ─',
      s4t2: '$598 Each',
      s4t3: 'Ideal for beef lovers craving for intense fla- vors - this set comes with a selection of melt-in-your-mouth A5 Kyushu Wagyu beef and the addictive beef liver and intestine.',
      s4t4: '─ Kyushu A5 Wagyu Beef Platter ─',
      s4t5: 'Bottom Round / Top Round / Chuck Eye Roll / Chuck cap / Short Plate / Short Rib / Liver / Intestine',
      s4t6: '─ more than 60 dishes all-you-can-eat ─',
      s4t7: '(10% Service Charge)',

      re: '─ Selected Menu ─',
      re1t1: 'Wagyu Offals',
      re1t2:
        'Beef offals have been a popular local delicacy among Japanese to be enjoyed with alcohol. At Miyazaki Niku Honpo, feast yourself with unlim- ited servings of beef offals including rare varieties such as beef heart, small intestine and reed tripe. All Wagyu beef offals are seasoned in a special marinade from Japan for your best enjoyment - itʼs best grilled over high heat for maximum umami and aroma from the beef fat.',
      re1List: [
        '(3 Choices per day)',
        'Heart /',
        'Liver /',
        'Book Tripe /',
        'Reed Tripe /',
        'Small Intestine / ',
        'Large Intestine',
      ],

      re2t1: 'Japanese Chicken',
      re2t2:
        'All chicken orders are marinated with a special seasoning blend for the best flavors - grill until crispy to get the most out of this luscious chicken.',
      re2List: [
        'Grilled Chicken Wing (Salt / Wasabi) /',
        'Grilled Chicken Neck (Salt and Scallion /',
        'Black Shichimi Spice / Chilli Spice) / ',
        'Grilled Chicken Thigh ',
        '(Homemade Herb Marinade / Salt and ',
        'Scallion / Chilli Spice / Yuzu Miso) / ',
        'Grilled Chicken Skin (Salt and Scallion / ',
        'Black Shichimi Spice)',
        '/ Grilled Chicken Patty with Cartilage ',
        'and Egg Yolk / Grilled Chicken Kidney ',
        'with Black Shichimi Spice / Grilled ',
        'Chicken Liver with Black Shichimi Spice / ',
        'Grilled Chicken Heart',
      ],

      re3t1: 'Japanese Kurobuta Pork',
      re3t2:
        'eautifully marbled and rich in vita- min E, Japanese Kurobuta pork is re- nowned for its tender and smooth texture with a light pink flesh. Itʼs best enjoyed by grilling over an open fire until slightly charred, filling your palate with the robust sweet and savory flavors and the mellow aroma of the pork fat.',
      re3List: [
        'Aomori Kurobuta Pork Belly (Yuzu',
        'Miso, Cheese, Chilli Spice, Ginger, ',
        'Black Shichimi Spice) / Kagoshima ',
        'Kurobuta Pork Shoulder (Black Shi- ',
        'chimi Spice, Yuzu Miso, Cheese, ',
        'Chilli Spice, Ginge) / Kagoshima ',
        'Kurobuta Pork Belly Dice with ',
        'Cheese / Kurobuta Pork Sausage ',
        'with Cheese',
      ],

      items: '─ All-you-can-eat ─',
      itemsList: [
        [
          'Japanese Jet-fresh Seafood',
          'Squid',
          'Scallops with Cheese and White Sauce',
          'Scallops with Sakura Shrimp Mayonnaise',
          'Hiroshima Oysters with Herbed Butter',
          'Hokkaido Ichishio Red Rockfish',
        ],
        [
          'Japanese Jet-fresh Vegetables',
          'Leek',
          'Sweet Potato',
          'Pumpkin',
          'Corn',
          'Japanese Baby Green Pepper',
          'Okra',
          'Baked Mushrooms in Mentaiko Butter',
          'Assorted Japanese Vegetables',
        ],
        [
          'Side Orders',
          'Edamame',
          'Assorted Japanese Pickles',
          'Crab and Miso Gratin',
          'Chicken Skin Dumpling',
          'Assorted Tempura',
          'Japanese Grilled Eggplant',
          'Japanese Rice Cake with Cheese',
          'Deep-fried Hiroshima Oyster',
          'Deep-fried Kumamoto Herb Chick-',
          'en',
          'Homemade Wagyu Dumpling',
        ],
        [
          'Salad',
          'Signature Japanese Salad',
          'Cabbage Salad',
          'Snow Crab and Yam Salad',
          'Tofu and Spinach Salad with Sesame',
          'Dressing',
          'Prawn Salad with Chilled Tofu',
        ],
        [
          'Rice Specialties',
          'Matsuba Crab Rice with Green ',
          'Tea Broth',
          'Crab Meat and Sea Urchin Rice',
          'Soup with Rice Crispies',
          'Signature Chicken Rice Soup',
          'Kyushu Wagyu and Squid Ink Rice',
          'Cake',
        ],
        [
          'Noodles Specialties',
          'Cold Noodles',
          'Soba',
          'Spicy Noodles',
          'Ichinokura Beef Short Ribs Ramen',
          'in Beef Bone Broth',
        ],
        ['Desserts', 'Sakura Ice-cream', 'Yuzu Ice-cream', '3.6 Milk Pudding'],
        [
          'Drinks',
          'Kyoho Grape Calpis Soda',
          'White Peach Calpis Soda',
          'Pineapple Calpis Soda',
          'Melon Calpis Soda',
          'Coca Cola',
          'Coca Cola Zero',
          'Green Tea',
          'Oolong Tea',
        ],
      ],
      footer: 'ABOUT',
      footer2: 'Website：miyazaki-nikuhonpo.com.hk',
      footer3: 'Email info@hatsubara.com.hk',
      footer4: 'Address: 6/F, Sugar+, 25-36 Sugar Street, Causeway Bay',
      footer5: 'Monday to Friday: 5pm-12am\nSaturday, Sunday and public holidays: 12pm-3pm, 5pm-12am',
      footer6: '＋852 3568 5330',

      bookingBtn: 'booking_btn_en',
      birthdayBtn: 'menuBirthdayButton_en',
      menuLogo: 'menuLogo_en',
      lunchMenuBtn: 'lunch_menu_en',
    },
  };

  return (
    <div className={classes.homePage}>
      {/*red area */}
      <div id={classes.wrapper}>
        {/* section 1 */}
        <section ref={refSection1}>
          <div id={classes.header}>
            {/*image background */}
            <img
              loading="lazy"
              src={require('../images/mobile/section1_background.png')}
              alt={'background'}
              style={{ position: 'absolute', left: 0, height: '100%', width: '100vw', zIndex: -1 }}
            />
            <div className={classes.headerBG} style={{ paddingBottom: 20 }}>
              <div className={classes.absoluteWrap} style={{ width: width * 0.8 }}>
                <img
                  loading="lazy"
                  src={require('../images/mobile/profile.png')}
                  width={width * 0.8}
                  alt={'cover'}
                  style={{ position: 'relative', marginTop: 20 }}
                />
                {/* <div className={classes.text} style={{ fontSize: 16, marginTop: 20 }}>
                  {TextMapping[lang].ms1t1}
                </div>
                <div className={classes.text} style={{ color: 'red' }}>
                  {TextMapping[lang].ms1t2}
                </div> */}
                <div style={{ fontSize: 14, color: '#FFF', textAlign: 'justify', marginTop: 20 }}>
                  {TextMapping[lang].ms1t3}
                </div>
                {/* media */}
                <div className={classes.contentMediaRow}>
                  <div className={classes.headerText}>
                    <img
                      loading="lazy"
                      src={require(`../images/${TextMapping[lang].bookingBtn}.png`)}
                      width={100}
                      height={30}
                      alt={'booking_btn'}
                      onClick={() =>
                        gotoPlatform(lang === Lang.zh ? OtherPlatform.InlineApp_zh : OtherPlatform.InlineApp_en)
                      }
                    />
                    <img
                      loading="lazy"
                      src={require('../images/fb.png')}
                      width={25}
                      height={25}
                      style={{ marginLeft: 16 }}
                      alt={'fb'}
                      onClick={() => gotoPlatform(OtherPlatform.Facebook)}
                    />
                    <img
                      loading="lazy"
                      src={require('../images/instagram.png')}
                      width={25}
                      height={25}
                      style={{ marginLeft: 16 }}
                      alt={'instagram'}
                      onClick={() => gotoPlatform(OtherPlatform.Instagram)}
                    />
                    <img
                      loading="lazy"
                      src={require('../images/whatsapp.png')}
                      width={25}
                      height={25}
                      style={{ marginLeft: 16, marginRight: 16 }}
                      alt={'instagram'}
                      onClick={() => gotoPlatform(OtherPlatform.Whatsapp)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* section 2 */}
        <section ref={refSection2}>
          <div id={classes.header}>
            {/*image background */}
            <img
              loading="lazy"
              src={require('../images/background/homepage_background_1_reflected.png')}
              alt={'background'}
              style={{ position: 'absolute', left: 0, height: '300px', width: '100vw', zIndex: -1, bottom: 0 }}
            />

            <div className={classes.headerBG}>
              <div className={classes.absoluteWrap} style={{ width: width * 0.7 }}>
                <h3
                  className={classes.text}
                  style={{ fontSize: 24, color: '#b48958', width: '100%', textAlign: 'center', marginTop: 20 }}
                >
                  {TextMapping[lang].ms2t1}
                </h3>
                <img
                  loading="lazy"
                  src={require('../images/mobile/section2_1.png')}
                  alt={'background'}
                  style={{ position: 'relative', width: '100%' }}
                />
                <h4 className={classes.text} style={{ color: '#c2a26b', fontSize: 14, marginTop: 5, marginLeft: 0 }}>
                  {TextMapping[lang].ms2t2}
                </h4>
                <div style={{ fontSize: 14, color: '#FFF', textAlign: 'justify' }}>{TextMapping[lang].ms2t3}</div>

                <div style={{ position: 'relative', marginTop: 20 }}>
                  <img
                    loading="lazy"
                    src={require('../images/page1/section2/pork1.png')}
                    alt={'background'}
                    style={{ position: 'relative', width: '100%' }}
                  />
                  <img
                    loading="lazy"
                    src={require('../images/page1/section6/cow_name_plate.png')}
                    alt={'background'}
                    style={{ position: 'absolute', width: '9%', left: width * 0.05 }}
                  />
                </div>
                <h4 className={classes.text} style={{ color: '#c2a26b', fontSize: 14, marginTop: 5, marginLeft: 0 }}>
                  {TextMapping[lang].ms2t4}
                </h4>
                <div style={{ fontSize: 14, color: '#FFF', textAlign: 'justify' }}>{TextMapping[lang].ms2t5}</div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 50,
                    marginBottom: 30,
                    width: '100%',
                    maxWidth: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      maxWidth: '100%',
                      marginBottom: 20,
                    }}
                  >
                    <img
                      loading="lazy"
                      src={require('../images/page1/section2/farm2.png')}
                      alt={'background'}
                      style={{ objectFit: 'contain' }}
                      width={'50%'}
                      height={'50%'}
                    />
                    <img
                      loading="lazy"
                      src={require('../images/page1/section2/image.png')}
                      alt={'background'}
                      style={{ objectFit: 'contain' }}
                      width={'50%'}
                      height={'50%'}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', flex: 1, marginLeft: 3 }}>
                    <div style={{ color: '#FFF', zoom: 0.4, fontSize: 12 }}>
                      ── 桜花牧場歷史──
                      <br />
                      平成15年(2003年)乙守畜產創業
                      <br />
                      平成22年(2010年)桜花牧場設立
                      <br />
                      <br />
                      ── 桜花牧場歷年獲得獎項──
                      <br />
                      平成16年(2004年)JA都城 支所牛品評會 チャンピオン(冠軍)
                      <br />
                      平成24年(2012年)JA都城 西部支部 枝肉共励会 最優秀賞
                      <br />
                      平成25年(2013年)JA都城 支所牛品評會 チャンピオン(冠軍)
                      <br />
                      平成25年(2013年)JA都城 上物率の部(5等級率) 第一位
                      <br />
                      平成25年(2013年)第129回 JA都城 枝肉共励会 最優秀賞
                      <br />
                      平成26年(2014年)JA都城 西部支部 枝肉共励会 最優秀賞
                      <br />
                      平成26年(2014年)第130回 JA都城 枝肉共励会 最優秀賞
                      <br />
                      平成27年(2015年)第134回 JA都城 枝肉共励会 優秀賞
                      <br />
                      平成28年(2016年)JA都城 3支部合同枝肉共励会 優秀賞
                      <br />
                      平成28年(2016年)JA都城 西部支部 枝肉共励会 最優秀賞
                      <br />
                      平成28年(2016年)JA都城 上物率の部(5等級率) 第一位
                      <br />
                      平成28年(2016年)JA都城 西部支部 枝肉共励会 優秀賞
                      <br />
                      平成28年(2016年)第137回 JA都城 枝肉共励会 優秀賞
                      <br />
                      平成29年(2017年)JA都城 上物率の部(5等級率) 第一位
                      <br />
                      平成29年(2017年)第139回 JA都城 枝肉共励会 優秀賞
                      <br />
                      平成29年(2017年)第140回 JA都城 枝肉共励会 最優秀賞
                      <br />
                      令和元年(2019年)第43回 九州管內系統和牛枝肉共励会 銅賞
                      <br />
                      令和2年(2020年)JA宮崎経済連系統和牛枝肉共励会 金賞
                      <br />
                      令和2年(2020年)第148回 JA都城 枝肉共進会 最優秀賞
                      <br />
                      令和2年(2020年)JA宮崎経済連系統和牛枝肉共励会 銀賞
                      <br />
                      令和2年(2020年)宮崎縣知事頒發表彰狀
                      <br />
                      令和3年(2021年)第63回宮崎県畜產共進会(肉牛枝肉の部)優等賞
                      <br />
                      令和3年(2021年)JA宮崎経済連系統和牛枝肉共励会 優秀賞
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* section 3 */}
        <section ref={refSection3}>
          <div
            id={classes.header}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#b8a07f' }}
          >
            {/*image background */}
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: width }}>
              <img
                loading="lazy"
                src={require('../images/page1/section3/background.png')}
                alt={'background'}
                style={{ position: 'absolute', left: 0, height: '100%', width: '100vw', zIndex: -1 }}
              />

              <img
                loading="lazy"
                src={require(`../images/mobile/${TextMapping[lang].section3T1}.png`)}
                width={width * 0.5}
                className={classes.headerImage}
                style={{ marginTop: 20 }}
                alt={''}
              />

              <img
                loading="lazy"
                src={require('../images/page1/section3/cow_section.png')}
                width={'100%'}
                className={classes.headerImage}
                alt={''}
              />
              <div
                className={classes.row}
                style={{
                  width: width * 0.9,
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  display: 'flex',
                }}
              >
                <pre style={{ color: '#633920', fontSize: 16, textAlign: 'left', fontWeight: 900, zoom: 0.5 }}>
                  {TextMapping[lang].ms3t2}
                </pre>
                <pre style={{ color: '#633920', fontSize: 16, textAlign: 'left', fontWeight: 900, zoom: 0.5 }}>
                  {TextMapping[lang].ms3t3}
                </pre>

                {isSmallerMobile ? (
                  <></>
                ) : (
                  <div
                    className={classes.column}
                    style={{
                      fontSize: 20,
                      color: '#633920',
                      width: 300,
                      fontWeight: 900,
                      // zoom: 0.5,
                    }}
                  >
                    {TextMapping[lang].ms3t4}
                    <h3 style={{ fontSize: 12 }}>{TextMapping[lang].ms3t5}</h3>
                    <h3 style={{ fontSize: 12 }}>{TextMapping[lang].ms3t6}</h3>
                    <h3 style={{ fontSize: 12 }}>{TextMapping[lang].ms3t7}</h3>
                    <h3 style={{ fontSize: 12 }}>{TextMapping[lang].ms3t8}</h3>
                  </div>
                )}
              </div>
              {isSmallerMobile ? (
                <div
                  className={classes.column}
                  style={{
                    fontSize: 20,
                    color: '#633920',
                    width: 300,
                    fontWeight: 900,
                    // zoom: 0.5,
                  }}
                >
                  {TextMapping[lang].ms3t4}
                  <h3 style={{ fontSize: 12 }}>{TextMapping[lang].ms3t5}</h3>
                  <h3 style={{ fontSize: 12 }}>{TextMapping[lang].ms3t6}</h3>
                  <h3 style={{ fontSize: 12 }}>{TextMapping[lang].ms3t7}</h3>
                  <h3 style={{ fontSize: 12 }}>{TextMapping[lang].ms3t8}</h3>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </section>
        {/* section 4 */}
        <section ref={refSection4} style={{ position: 'relative' }}>
          <>
            <img
              loading="lazy"
              src={require('../images/background/homepage_background_1.png')}
              alt={'background'}
              style={{ position: 'absolute', left: 0, height: '400px', width: '100%' }}
            />
            <img
              loading="lazy"
              src={require('../images/background/homepage_background_1_reflected.png')}
              alt={'background'}
              style={{ position: 'absolute', bottom: 0, left: 0, height: '400px', width: '100%' }}
            />
            <div
              style={{
                position: 'relative',
                left: 0,
                paddingBottom: '45%',
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              <img
                src={require(`../images/page2/${TextMapping[lang].menuLogo}.png`)}
                alt="宮崎肉本舗"
                width="100%"
                style={{ position: 'absolute', left: 0, top: 0 }}
              />
            </div>
            <div id={classes.session1} className={classes.session}>
              <div className={classes.productImgRow}>
                <img
                  loading="lazy"
                  src={require('../images/page2/Set_01.jpg')}
                  style={{ width: width * 0.8 }}
                  alt={''}
                />
                <img
                  loading="lazy"
                  src={require('../images/page1/section6/cow_name_plate.png')}
                  style={{ width: width * 0.1 }}
                  alt={''}
                />
              </div>
              <div className={classes.remark}>{TextMapping[lang].ms6t1}</div>
              <div className={classes.remark}>{TextMapping[lang].ms6t2}</div>
              <div className={classes.textWrapper}>
                <h2>{TextMapping[lang].s1t1}</h2>
                <h3>{TextMapping[lang].s1t2}</h3>
                <h5>{TextMapping[lang].s1t3}</h5>
                <h4 style={{ margin: '10px 0 0 0' }}>{TextMapping[lang].s1t4}</h4>
                <h5 style={{ lineHeight: 1.6 }}>{TextMapping[lang].s1t5_1}</h5>
                {lang === Lang.zh ? (
                  <>
                    <h5 style={{ lineHeight: 1.6 }}>{TextMapping[lang].s1t5_2}</h5>
                    <h5 style={{ lineHeight: 1.6 }}>{TextMapping[lang].s1t5_3}</h5>
                  </>
                ) : (
                  <></>
                )}

                <div className={classes.framedText}>
                  <h4>{TextMapping[lang].s1t6}</h4>
                  <h5>{TextMapping[lang].s1t7}</h5>
                  <h5>{TextMapping[lang].s1t8}</h5>
                </div>
                <h2 style={{ lineHeight: '1' }}>+</h2>
                <h4>{TextMapping[lang].s1t9}</h4>
                <h5>{TextMapping[lang].s1t10}</h5>
              </div>
            </div>

            <div id={classes.session2} className={classes.session}>
              <div className={classes.productImgRow}>
                <img
                  loading="lazy"
                  src={require('../images/page2/Set_02.jpg')}
                  style={{ width: width * 0.8 }}
                  alt={''}
                />
              </div>
              <div className={classes.remark}>{TextMapping[lang].ms6t1}</div>
              <div className={classes.remark}>{TextMapping[lang].ms6t2}</div>
              <div className={classes.textWrapper}>
                <h2>{TextMapping[lang].s2t1}</h2>
                <h3>{TextMapping[lang].s2t2}</h3>
                <h4 style={{ margin: '10px 0 0 0' }}>{TextMapping[lang].s2t4}</h4>
                <h5 style={{ lineHeight: 1.6 }}>{TextMapping[lang].s2t5}</h5>
                <div className={classes.framedText}>
                  <h4>{TextMapping[lang].s2t6}</h4>
                  <h5>{TextMapping[lang].s2t7}</h5>
                  <h5>{TextMapping[lang].s2t8}</h5>
                </div>
                <h2 style={{ lineHeight: '1' }}>+</h2>
                <h4>{TextMapping[lang].s2t9}</h4>
                <h5>{TextMapping[lang].s2t10}</h5>
              </div>
            </div>

            <div id={classes.session3} className={classes.session}>
              <div className={classes.productImgRow}>
                <img
                  loading="lazy"
                  src={require('../images/page2/Set_03.jpg')}
                  style={{ width: width * 0.8 }}
                  alt={''}
                />
              </div>
              <div className={classes.remark}>{TextMapping[lang].ms6t1}</div>
              <div className={classes.remark}>{TextMapping[lang].ms6t2}</div>

              <div className={classes.textWrapper}>
                <h2>{TextMapping[lang].s3t1}</h2>
                <h3>{TextMapping[lang].s3t2}</h3>
                <h4 style={{ margin: '10px 0 0 0' }}>{TextMapping[lang].s3t4}</h4>
                <h5 style={{ width: '80%', lineHeight: 1.6 }}>{TextMapping[lang].s3t5}</h5>
                <h5 style={{ width: '80%', lineHeight: 1.6 }}>{TextMapping[lang].s3t5_1}</h5>
                <h2 style={{ lineHeight: '1' }}>+</h2>
                <h4>{TextMapping[lang].s3t6}</h4>
                <h5>{TextMapping[lang].s3t6_1}</h5>
                <h2 style={{ lineHeight: '1' }}>+</h2>
                <h4>{TextMapping[lang].s3t7_1}</h4>
                <h5>{TextMapping[lang].s3t7}</h5>
                <h5>{TextMapping[lang].s3t7_2}</h5>
              </div>
            </div>

            <div id={classes.session4} className={classes.session} style={{ alignItems: 'center' }}>
              <a
                href={require(`../documents/lunch_menu.pdf`)}
                rel="noopener noreferrer"
                target="_blank"
                style={{ width: '60%' }}
              >
                <img loading="lazy" src={require(`../images/lunch_menu_${lang}.png`)} alt={''} />
              </a>
            </div>
          </>
        </section>
        <div className={classes.menuRelated}>
          <div className={classes.menuBanner}>
            <img src={require(`../images/page1/section7/${TextMapping[lang].ms5Header}.png`)} alt="" width="100%" />
          </div>
          <div className={`${classes.session} ${classes.session5}`} style={{ width: '100%', padding: 0 }}>
            <h2 style={{ padding: '12px 0', fontWeight: 600 }}>{TextMapping[lang].re}</h2>
            <div className={classes.recommend}>
              <div className={classes.description}>
                <img src={require('../images/page2/recommend1.jpg')} alt="1" />
                <h2>{TextMapping[lang].re1t1}</h2>
                <h6 style={{ width: 330 }}>{TextMapping[lang].re1t2}</h6>
                <div className={classes.framedText}>
                  <pre>
                    {TextMapping[lang].re1List.map((item) => (
                      <>{item + '\n'}</>
                    ))}
                  </pre>
                </div>
              </div>
              <div className={classes.description}>
                <img src={require('../images/page2/recommend2.jpg')} alt="1" />
                <h2>{TextMapping[lang].re2t1}</h2>
                <h6 style={{ width: 360 }}>{TextMapping[lang].re2t2}</h6>
                <div className={classes.framedText} style={{ width: 350 }}>
                  <pre>
                    {TextMapping[lang].re2List.map((item) => (
                      <>{item + '\n'}</>
                    ))}
                  </pre>
                </div>
              </div>
              <div className={classes.description}>
                <img src={require('../images/page2/recommend3.jpg')} alt="1" />
                <h2>{TextMapping[lang].re3t1}</h2>
                <h6 style={{ width: 290 }}>{TextMapping[lang].re3t2} </h6>
                <div className={classes.framedText}>
                  <pre>
                    {TextMapping[lang].re3List.map((item) => (
                      <>{item + '\n'}</>
                    ))}
                  </pre>
                </div>
              </div>
            </div>

            <div className={classes.titlewrapper}>
              <h2 className={classes.title}>{TextMapping[lang].items}</h2>
            </div>
            <div className={classes.items}>
              {_Array.range(0, 3, 1).map((index) => (
                <div className={classes.item}>
                  <h2>
                    {TextMapping[lang].itemsList[index * 2][0]}
                    {index === 2 && lang === Lang.zh ? (
                      <span style={{ fontSize: '16px', fontWeight: 400 }}>（日本米使用）</span>
                    ) : (
                      <></>
                    )}
                  </h2>
                  {index === 2 && lang === Lang.en ? (
                    <span style={{ fontSize: '16px', fontWeight: 400, color: '#b48958' }}>
                      （Fukui Prefecture Koshihikari Rice)
                    </span>
                  ) : (
                    <></>
                  )}
                  <pre>
                    {TextMapping[lang].itemsList[index * 2].slice(1).map((item) => (
                      <>{item + '\n'}</>
                    ))}
                  </pre>
                  <h2>{TextMapping[lang].itemsList[index * 2 + 1][0]}</h2>
                  <pre>
                    {TextMapping[lang].itemsList[index * 2 + 1].slice(1).map((item) => (
                      <>{item + '\n'}</>
                    ))}
                  </pre>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* section 9 */}
      <section ref={refSection5}>
        <div id={classes.headerSection9}>
          <div
            className={classes.absoluteWrap}
            style={{
              padding: '10px 30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundSize: 'contain',
              backgroundRepeat: 'repeat-x',
            }}
          >
            <img
              loading="lazy"
              src={require(`../images/page1/section9/${TextMapping[lang].ms9Header}.png`)}
              width={'80%'}
              alt={''}
            />
          </div>
        </div>
      </section>
      {/* section 10 */}
      <div id={classes.headerSection10}>
        <div className={classes.base}>
          <div
            className={classes.absoluteWrap}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: width * 0.8,
                alignItems: 'center',
                marginTop: 20,
              }}
            >
              <img
                loading="lazy"
                src={require('../images/page1/section10/Beef_01.jpg')}
                width={width * 0.6}
                className={classes.img}
                alt={''}
              />
              <h2 style={{ color: '#b48958', fontSize: 26, fontWeight: 'normal', lineHeight: 1.1, marginBottom: 20 }}>
                <br />
                {TextMapping[lang].ms10p1t1}
                <br />
                {TextMapping[lang].ms10p1t2}
              </h2>
              {lang === Lang.zh ? (
                <div style={{ color: '#FFF', textAlign: 'justify', lineHeight: 2, fontSize: 14 }}>
                  {TextMapping[lang].ms10p1b1}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: width * 0.8,
                alignItems: 'center',
                marginTop: 20,
              }}
            >
              <img
                loading="lazy"
                src={require('../images/page1/section10/Beef_02.jpg')}
                width={width * 0.6}
                className={classes.img}
                alt={''}
              />
              <h2 style={{ color: '#b48958', fontSize: 26, fontWeight: 'normal', lineHeight: 1.1, marginBottom: 20 }}>
                <br />
                {TextMapping[lang].ms10p2t2}
                <br />
                {TextMapping[lang].ms10p2t3}
              </h2>
              {lang === Lang.zh ? (
                <div style={{ color: '#FFF', textAlign: 'justify', lineHeight: 2, fontSize: 14 }}>
                  {TextMapping[lang].ms10p2b1}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: width * 0.8,
                alignItems: 'center',
                marginTop: 20,
              }}
            >
              <img
                loading="lazy"
                src={require('../images/page1/section10/Beef_03.jpg')}
                width={width * 0.6}
                className={classes.img}
                alt={''}
              />
              <h2
                style={{
                  color: '#b48958',
                  fontSize: 26,
                  fontWeight: 'normal',
                  lineHeight: 1.1,
                  marginBottom: 20,
                  marginTop: 10,
                }}
              >
                {lang === Lang.zh ? (
                  <>
                    <br />
                  </>
                ) : (
                  <></>
                )}
                {TextMapping[lang].ms10p3t2}
                <br />
                {TextMapping[lang].ms10p3t3}
              </h2>
              {lang === Lang.zh ? (
                <div style={{ color: '#FFF', textAlign: 'justify', lineHeight: 2, fontSize: 14 }}>
                  {TextMapping[lang].ms10p3b1}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* section 11 */}
      <section ref={refSection6}>
        <div id={classes.headerSection11}>
          <div
            className={classes.absoluteWrap}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: width * 0.9 }}>
              <h2 style={{ color: '#b48958', fontSize: 26, marginTop: 30, marginBottom: 20 }}>
                {TextMapping[lang].ms11t1}
              </h2>
              <div className={classes.row}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <img
                    loading="lazy"
                    src={require('../images/page1/section11/hk_shop_1.png')}
                    style={{ marginRight: 3 }}
                    width={width * 0.3}
                    alt={'hk_shop'}
                  />
                  <img
                    loading="lazy"
                    src={require('../images/page1/section11/hk_shop_2.png')}
                    style={{ marginRight: 3 }}
                    width={width * 0.3}
                    alt={'hk_shop'}
                  />
                  <img
                    loading="lazy"
                    width={width * 0.3}
                    src={require('../images/page1/section11/hk_shop_3.png')}
                    alt={'hk_shop'}
                  />
                </div>
                <div>
                  <div style={{ color: '#FFF', textAlign: 'justify', marginTop: 20 }}>
                    {TextMapping[lang].ms11t2}
                    <br />
                    <br />
                    <pre style={{ fontSize: lang === Lang.zh ? 16 : 12, color: '#FFF' }}>
                      {TextMapping[lang].ms11t3}
                    </pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className={classes.whiteBanner}>
        <h3>{TextMapping[lang].footer}</h3>
        {/* <p>{TextMapping[lang].footer1}</p> */}
        <p>{TextMapping[lang].footer2}</p>
        <p>{TextMapping[lang].footer3}</p>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <button>
            <img
              src={require('../images/page2/fb.png')}
              alt=""
              width={30}
              onClick={() => gotoPlatform(OtherPlatform.Facebook)}
            />
          </button>
          <div style={{ width: 20 }} />
          <button>
            <img
              src={require('../images/page2/ig.png')}
              alt=""
              width={30}
              onClick={() => gotoPlatform(OtherPlatform.Instagram)}
            />
          </button>
          <div style={{ width: 20 }} />
          <button>
            <img
              src={require('../images/whatsapp.png')}
              alt=""
              width={30}
              onClick={() => gotoPlatform(OtherPlatform.Whatsapp)}
            />
          </button>
        </div>
        <button
          onClick={() => gotoPlatform(lang === Lang.zh ? OtherPlatform.InlineApp_zh : OtherPlatform.InlineApp_en)}
        >
          <img src={require(`../images/${TextMapping[lang].bookingBtn}.png`)} alt="" width={120} />
        </button>
        <br />
        <button
          onClick={() =>
            gotoPlatform(
              isAndroid
                ? OtherPlatform.Member_mobile_Android
                : isIOS
                ? OtherPlatform.Member_mobile_iOS
                : OtherPlatform.Member_website
            )
          }
        >
          <img src={require(`../images/page2/${TextMapping[lang].birthdayBtn}.png`)} alt="" width={120} />
        </button>
        <p>{TextMapping[lang].footer4}</p>
        <p>{TextMapping[lang].footer5}</p>
        <p>{TextMapping[lang].footer6}</p>
        <h6>miyazaki-nikuhonpo.com.hk</h6>
      </div>

      <button className={classes.menuButton} onClick={() => setisNavMenuOpen(true)}></button>
      {/* <div className={classes.navMenu}>hi</div> */}
      <Drawer isShow={isNavMenuOpen} onClose={() => setisNavMenuOpen(false)}>
        <div className={classes.navMenu}>
          <div className={classes.navButton} onClick={() => scrollToSection(refSection1)}>
            <p>{TextMapping[Lang.zh].nav1}</p>
          </div>
          <div className={classes.navButton} onClick={() => scrollToSection(refSection2)}>
            <p>{TextMapping[Lang.zh].nav2}</p>
          </div>
          <div className={classes.navButton} onClick={() => scrollToSection(refSection3)}>
            <p>{TextMapping[Lang.zh].nav3}</p>
          </div>
          <div className={classes.navButton} onClick={() => scrollToSection(refSection4)}>
            <p>{TextMapping[Lang.zh].nav4}</p>
          </div>
          <div className={classes.navButton} onClick={() => scrollToSection(refSection5)}>
            <p>{TextMapping[Lang.zh].nav5}</p>
          </div>
          <div className={classes.navButton} onClick={() => scrollToSection(refSection6)}>
            <p>{TextMapping[Lang.zh].nav6}</p>
          </div>
          <div className={classes.navButton}>
            <p
              onClick={() => {
                setLang(Lang.zh);
                setisNavMenuOpen(false);
                changeWebsiteTitle(Lang.zh);
              }}
            >
              中
            </p>
            <p style={{ marginLeft: 10, marginRight: 10 }}>{' / '}</p>
            <p
              onClick={() => {
                setLang(Lang.en);
                setisNavMenuOpen(false);
                changeWebsiteTitle(Lang.en);
              }}
            >
              Eng
            </p>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default HomePageMobile;

class _Array<T> extends Array<T> {
  static range(from: number, to: number, step: number): number[] {
    return Array.from(Array(Math.floor((to - from) / step) + 1)).map((v, k) => from + k * step);
  }
}
