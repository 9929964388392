import classes from './MenuPage.module.scss';

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { changeWebsiteTitle, gotoPlatform, Lang, OtherPlatform } from '../utils/util';

interface Props {
  language: Lang;
}
const MenuPage: React.FC<Props> = (props) => {
  useEffect(() => {
    document.title = props.language === Lang.zh ? '宮崎肉本舖（香港店）' : 'Miyazaki Niku Honpo (Hong Kong)';
  }, []);

  let navigate = useNavigate();
  const goToHomePage = (toSection: string) => {
    let path = lang === Lang.zh ? `/#${toSection}` : `/en#${toSection}`;
    window.scrollTo(0, 0);
    navigate(path);
  };

  const [lang, setLang] = useState(props.language);

  const TextMapping = {
    [Lang.zh]: {
      ms5Header: 'text',

      nav1: '首頁',
      nav2: '桜花牛.桜花牧場',
      nav3: '一頭牛燒肉',
      nav4: '燒肉放題',
      nav5: '稀有部位',
      nav6: '有關我們',

      s1t1: '─ 宮崎A5桜花牛極上燒肉放題 ─',
      s1t2: '每位 $998',
      s1t3: '店舖獨家進口的A5桜花牛，每年只出產數十隻，相當矜貴。經過日本職人的細心培育，每隻牛的油花分布標準BMS均達10級，又因食用多種天然飼料的關係，肉質份外幼嫩，油脂分布特別幼細，且脂肪溶點與人體體溫相若，故入口油香特別強烈。客人可以由赤身至霜降，口味從輕到重，一次嘗遍10種不同部位，細味日本頂級的宮崎牛。',
      s1t4: '─ 4款 A5桜花牛 ─',
      s1t5_1: '稀有部位和尚頭‧前腿肉',
      s1t5_2: '西冷扒或肉眼扒 (廚師發辦)',
      s1t5_3: '壽喜燒 (附雞蛋一隻)',
      s1t6: '─ 任點 6款 A5桜花牛 ─',
      s1t7: '外後腿肉‧內後腿肉‧肩里肌',
      s1t8: '肩口肉‧五花腩‧牛肋肉',
      s1t9: '─ 60款日本食材放題 ─',
      s1t10: '(全單需惠顧相同套餐 , 另收加一服務費)',

      s2t1: '─ A5宮崎牛嚴選燒肉放題 ─',
      s2t2: '每位 $898',
      s2t3: '和牛拼盤嚴選A5宮崎牛8個最優質的部位,組合出集和牛肉香、油香於一身的好滋味,冰鮮空運到港的桜花牛鮮度滿分,肉汁豐沛,口感嫩滑,套餐還可無限追加A5宮崎牛。',
      s2t3_1: 'Chuck Cap / Short Plate / Short Rib / Sukiyaki (with Egg)',
      s2t4: '─ 1款 A5宮崎牛 ─',
      s2t5_1: '西冷或肉眼片 (廚師發辦)',
      s2t5_2: '壽喜燒 (附雞蛋一隻)',
      s2t6: '─ 任點 6款 A5宮崎牛 ─',
      s2t7: '外後腿肉‧內後腿肉‧肩里肌',
      s2t8: '肩口肉‧五花腩‧牛肋肉',
      s2t9: '─ 60款日本食材放題 ─',
      s2t10: '(全單需惠顧相同套餐 , 另收加一服務費)',

      s3t1: '─ A5宮崎牛特上燒肉放題 ─',
      s3t2: '每位 $698',
      s3t3: '挑選油花密布又雪白的A5級的特上九州黑毛和牛,集齊 西冷薄片、肉眼薄片、外後腿肉及肩口肉等9個不同部位,各有獨一無二的口感和味道,適合追求油香的和牛控。',
      s3t4: '─ 4款 A5宮崎牛 ─',
      s3t5: '內後腿肉‧肩里肌 肩口肉‧牛肋肉',
      s3t6: '─ 任點 2款A5宮崎牛 ─',
      s3t6_1: '外後腿肉‧五花腩',
      s3t7: '(全單需惠顧相同套餐 , 另收加一服務費)',
      s3t7_1: '─ 60款日本食材放題 ─',
      s3t7_2: '(不包括海鮮)',

      s4t1: '─ 九州A5和牛套餐 ─',
      s4t2: '每位 $598',
      s4t3: '來自九州的A5和牛色澤鮮嫩紅潤,油脂滿瀉, 和牛入口肥香油潤,肉質鬆化有濃郁的油香,還 可以嘗到牛肝及牛腸兩大內臟名物,燒來特別 惹味香口,嗜重口味者必選。',
      s4t4: '─ 7款九州A5和牛拼盤 ──',
      s4t5: '外後腿肉‧內後腿肉‧肩里肌‧肩口肉 ‧ 五花腩 ‧ 牛肋肉 ‧ 牛 肝 ‧ 牛 腸',
      s4t6: '─ 60款日本食材放題 ─',
      s4t7: '(全單需惠顧相同套餐 , 另收加一服務費)',

      re: '──精選放題單品──',

      re1t1: '和牛內臟',
      re1t2:
        '店舖秉承使用日本食材的理念，因此不惜工本，連帶清洗、醃味等工序，為食客帶來日本國民滋味。每日放題都會供應三款精選內臟，牛迷確實不可錯過。',
      re1List: ['(每日供應三款)', '牛心', '牛肝', '大腸', '丸腸', '牛栢葉', '牛沙瓜', '草肚', '牛肚', '牛肺', '牛尾'],

      re2t1: '日本雞肉',
      re2t2:
        '九州熊本產的「うまかハーブ鶏」(Umaka香草雞)以混合百里香、鼠尾草和迷迭香等的飼料餵飼，雞肉脂肪量含量較低，肉質爽口嫩滑，入口嘗到濃郁雞味之餘，隱隱滲出淡淡香草味。',
      re2List: [
        '雞中翼（鹽燒、山葵）',
        '雞皮（鹽葱、黑七味）',
        '雞腿肉（自家製香草醬、鹽葱、辛口、柚子味噌）',
        '雞頸肉（鹽葱、黑七味、辛口）',
        '日式免治雞肉軟骨棒',
        '雞腎 (黑七味)',
        '雞肝 (黑七味)',
        '燒汁雞心',
        '雞冠 (辛口)',
      ],

      re3t1: '日本黑豚',
      re3t2:
        '含豐富維他命E和均勻脂肪的日本黑豚，肉色粉紅而油脂帶白，柔軟鬆化。明火燒烤可令油脂半溶，逼出香氣，油脂豐腴之餘，帶有獨特的甘味及甜香。',
      re3List: [
        '黑豚五花腩',
        '（柚子味噌、辛口、生薑、黑七味、鹽葱）',
        '黑豚上肩肉',
        '（柚子味噌、辛口、生薑、黑七味、鹽葱）',
        '黑豚頸肉',
        '（柚子味噌、辛口、生薑、黑七味、鹽葱）',
        '日式黑豚芝士腸',
      ],

      items: '──放題單品──',
      itemsList: [
        ['日本直送海產', '辣汁魷魚', '白汁芝士帆立貝', '櫻花蝦蛋黃醬帆立貝', '香草牛油廣島蠔', '北海道一汐赤魚'],
        ['日本直送野菜', '京葱', '甜薯', '南瓜', '白粟米', '青椒仔', '秋葵', '明太子牛油野菌', '野菜盛合'],
        [
          '小食',
          '枝豆',
          '漬物三品',
          '蟹味噌甲羅燒',
          '雞皮餃子',
          '雜錦天婦羅',
          '芝士年糕',
          '吉列廣島蠔',
          '熊本縣產香草雞炸雞',
          '自家製九州和牛黑松露餃子',
          '自家製九州和牛辛口餃子',
        ],
        ['沙律', '宮崎肉本舖和風沙律', '椰菜沙律', '冷豆腐海老沙律', '胡麻豆腐菠菜沙律', '淮山松葉蟹肉沙律'],
        ['米飯', '松葉蟹肉茶漬飯', '海膽蟹肉脆米魚湯泡飯', '味自慢雞肉飯', '山賊汁九州和牛米餅', '天然飯'],
        ['粉麵', '一之藏肋條牛骨湯拉麵', '廣島蠔和牛豆乳湯烏冬', '九州胡麻冷麵', '蕎麥麵', '辣麵'],
        ['甜品', '雪糕 (多款口味)', '3.6 特濃牛乳奶凍 (限量供應)'],
        ['飲品', '乳酸梳打 (多款口味)', '冰茶', '可口可樂', '零糖可口可樂'],
      ],
      footer: '關於我們',
      footer2: '網址：miyazaki-nikuhonpo.com.hk',
      footer3: '電郵： info@hatsubara.com.hk',
      footer4: '地址：香港銅鑼灣糖街25-31號Sugar＋6樓',
      footer5: '營業時間:星期一至五: 5pm-12am\n星期六、日及公眾假期: 12pm-3pm, 5pm-12am',
      footer6: '查詢/訂座:3568 5330 (WhatsApp:6880 8908)',

      bookingBtn: 'booking_btn_zh',
      birthdayBtn: 'menuBirthdayButton_zh',
      menuLogo: 'menuLogo_zh',

      ms6t1: '*圖中為四人份量',
      ms6t2: '*圖片只供參考',
    },
    [Lang.en]: {
      ms5Header: 'text_en',

      nav1: 'Top',
      nav2: 'Ouka Farm.Ouka Beef',
      nav3: 'Nose to Tail Yakiniku',
      nav4: 'Set.All-you-can-eat',
      nav5: 'Rare Cuts',
      nav6: 'Contact Us',

      s1t1: '─ Miyazaki A5 Ouka Beef Supreme Set ─',
      s1t2: '$998 Each',
      s1t3: 'The ultimate choice for beef connoisseurs - a refined selection of the highest grade Miyazaki A5 Ouka Wagyu with the best cuts in- cluding the signature sirloin, spencer roll, short rib, and rare cuts such as knuckle and the melt-in-your-mouth chuck cap for a heavenly experience.',
      s1t4: '─ Miyazaki A5 Ouka Beef Supreme Platter ─',
      s1t5_1:
        'Sirloin & Spencer Roll / Bottom Round / Top Round / Chuck / Chuck Eye Roll / Chuck cap / Knuckle / Short Plate / Short Rib / Sukiyaki (with Egg)',
      s1t5_2:
        'Sirloin & Spencer Roll / Bottom Round / Top Round / Chuck / Chuck Eye Roll / Chuck cap / Knuckle / Short Plate / Short Rib / Sukiyaki (with Egg)',
      s1t5_3:
        'Sirloin & Spencer Roll / Bottom Round / Top Round / Chuck / Chuck Eye Roll / Chuck cap / Knuckle / Short Plate / Short Rib / Sukiyaki (with Egg)',
      s1t6: '─ 6 Types All you can eat Ouka Beef ─',
      s1t7: 'Bottom Round / Top Round / Chuck Eye Roll',
      s1t8: 'Chuck cap / Short Plate / Short Rib',
      s1t9: '─ more than 60 dishes all-you-can-eat ─',
      s1t10: '(10% Service Charge)',

      s2t1: '─ A5 Miyazaki Beef Premium Set ─',
      s2t2: '$898 Each',
      s2t3: 'Bottom Round / Top Round / Chuck Eye Roll / ',
      s2t3_1: 'Chuck Cap / Short Plate / Short Rib / Sukiyaki (with Egg)',
      s2t4: '─ A5 Miyazaki Beef Premium Platter ─',
      s2t5_1:
        'Sirloin or Spencer Roll / Bottom Round / Top Round / Chuck Eye Roll / Chuck cap / Short Plate / Short Rib / Sukiyaki (with Egg)',
      s2t5_2:
        'Sirloin or Spencer Roll / Bottom Round / Top Round / Chuck Eye Roll / Chuck cap / Short Plate / Short Rib / Sukiyaki (with Egg)',
      s2t6: '─ 6 Types All you can eat A5 Miyazaki Beef ─',
      s2t7: 'Bottom Round / Top Round / Chuck Eye Roll',
      s2t8: 'Chuck cap / Short Plate / Short Rib',
      s2t9: '─ more than 60 dishes all-you-can-eat ─',
      s2t10: '(10% Service Charge)',

      s3t1: '─ A5 Miyazaki Beef Premium Set ─',
      s3t2: '$698 Each',
      s3t3: 'Perfect for those going after for the rich, savory flavor of wagyu beef fat. Enjoy and compare the contrasting flavors and textures among 9 different cuts of premium A5 Kyushu Kuroge Wagyu including sliced sirloin, spencer roll, bottom round, top round and more.',
      s3t4: '─ A5 Miyazaki Beef Premium Platter ─',
      s3t5: 'Top Round / Chuck Eye Roll / Bottom Round / Short Plate',
      s3t6: '─ 2 Types All you can eat A5 Miyazaki Beef ─',
      s3t6_1: 'Bottom Round / Short Plate',
      s3t7: '(10% Service Charge)',
      s3t7_1: '─ more than 60 dishes all-you-can-eat ─',
      s3t7_2: '(not including Seafood)',

      s4t1: '─ Kyushu A5 Wagyu Beef Set ─',
      s4t2: '$598 Each',
      s4t3: 'Ideal for beef lovers craving for intense fla- vors - this set comes with a selection of melt-in-your-mouth A5 Kyushu Wagyu beef and the addictive beef liver and intestine.',
      s4t4: '─ Kyushu A5 Wagyu Beef Platter ─',
      s4t5: 'Bottom Round / Top Round / Chuck Eye Roll / Chuck cap / Short Plate / Short Rib / Liver / Intestine',
      s4t6: '─ more than 60 dishes all-you-can-eat ─',
      s4t7: '(10% Service Charge)',

      re: '─ Selected Menu ─',
      re1t1: 'Wagyu Offals',
      re1t2:
        'Beef offals have been a popular local delicacy among Japanese to be enjoyed with alcohol. At Miyazaki Niku Honpo, feast yourself with unlim- ited servings of beef offals including rare varieties such as beef heart, small intestine and reed tripe. All Wagyu beef offals are seasoned in a special marinade from Japan for your best enjoyment - itʼs best grilled over high heat for maximum umami and aroma from the beef fat.',
      re1List: [
        '(3 Choices per day)',
        'Heart /',
        'Liver /',
        'Book Tripe /',
        'Reed Tripe /',
        'Small Intestine / ',
        'Large Intestine',
      ],

      re2t1: 'Japanese Chicken',
      re2t2:
        'All chicken orders are marinated with a special seasoning blend for the best flavors - grill until crispy to get the most out of this luscious chicken.',
      re2List: [
        'Grilled Chicken Wing (Salt / Wasabi) /',
        'Grilled Chicken Neck (Salt and Scallion /',
        'Black Shichimi Spice / Chilli Spice) / ',
        'Grilled Chicken Thigh ',
        '(Homemade Herb Marinade / Salt and ',
        'Scallion / Chilli Spice / Yuzu Miso) / ',
        'Grilled Chicken Skin (Salt and Scallion / ',
        'Black Shichimi Spice)',
        '/ Grilled Chicken Patty with Cartilage ',
        'and Egg Yolk / Grilled Chicken Kidney ',
        'with Black Shichimi Spice / Grilled ',
        'Chicken Liver with Black Shichimi Spice / ',
        'Grilled Chicken Heart',
      ],

      re3t1: 'Japanese Kurobuta Pork',
      re3t2:
        'eautifully marbled and rich in vita- min E, Japanese Kurobuta pork is re- nowned for its tender and smooth texture with a light pink flesh. Itʼs best enjoyed by grilling over an open fire until slightly charred, filling your palate with the robust sweet and savory flavors and the mellow aroma of the pork fat.',
      re3List: [
        'Aomori Kurobuta Pork Belly (Yuzu',
        'Miso, Cheese, Chilli Spice, Ginger, ',
        'Black Shichimi Spice) / Kagoshima ',
        'Kurobuta Pork Shoulder (Black Shi- ',
        'chimi Spice, Yuzu Miso, Cheese, ',
        'Chilli Spice, Ginge) / Kagoshima ',
        'Kurobuta Pork Belly Dice with ',
        'Cheese / Kurobuta Pork Sausage ',
        'with Cheese',
      ],

      items: '─ All-you-can-eat ─',
      itemsList: [
        [
          'Japanese Jet-fresh Seafood',
          'Squid',
          'Scallops with Cheese and White Sauce',
          'Scallops with Sakura Shrimp Mayonnaise',
          'Hiroshima Oysters with Herbed Butter',
          'Hokkaido Ichishio Red Rockfish',
        ],
        [
          'Japanese Jet-fresh Vegetables',
          'Leek',
          'Sweet Potato',
          'Pumpkin',
          'Corn',
          'Japanese Baby Green Pepper',
          'Okra',
          'Baked Mushrooms in Mentaiko Butter',
          'Assorted Japanese Vegetables',
        ],
        [
          'Side Orders',
          'Edamame',
          'Assorted Japanese Pickles',
          'Crab and Miso Gratin',
          'Chicken Skin Dumpling',
          'Assorted Tempura',
          'Japanese Grilled Eggplant',
          'Japanese Rice Cake with Cheese',
          'Deep-fried Hiroshima Oyster',
          'Deep-fried Kumamoto Herb Chick-',
          'en',
          'Homemade Wagyu Dumpling',
        ],
        [
          'Salad',
          'Signature Japanese Salad',
          'Cabbage Salad',
          'Snow Crab and Yam Salad',
          'Tofu and Spinach Salad with Sesame',
          'Dressing',
          'Prawn Salad with Chilled Tofu',
        ],
        [
          'Rice Specialties',
          'Matsuba Crab Rice with Green ',
          'Tea Broth',
          'Crab Meat and Sea Urchin Rice',
          'Soup with Rice Crispies',
          'Signature Chicken Rice Soup',
          'Kyushu Wagyu and Squid Ink Rice',
          'Cake',
        ],
        [
          'Noodles Specialties',
          'Cold Noodles',
          'Soba',
          'Spicy Noodles',
          'Ichinokura Beef Short Ribs Ramen',
          'in Beef Bone Broth',
        ],
        ['Desserts', 'Sakura Ice-cream', 'Yuzu Ice-cream', '3.6 Milk Pudding'],
        [
          'Drinks',
          'Kyoho Grape Calpis Soda',
          'White Peach Calpis Soda',
          'Pineapple Calpis Soda',
          'Melon Calpis Soda',
          'Coca Cola',
          'Coca Cola Zero',
          'Green Tea',
          'Oolong Tea',
        ],
      ],
      footer: 'ABOUT',
      footer2: 'Website：miyazaki-nikuhonpo.com.hk',
      footer3: 'Email info@hatsubara.com.hk',
      footer4: 'Address: 6/F, Sugar+, 25-36 Sugar Street, Causeway Bay',
      footer5: 'Monday to Friday: 5pm-12am\nSaturday, Sunday and public holidays: 12pm-3pm, 5pm-12am',
      footer6: '＋852 3568 5330',

      bookingBtn: 'booking_btn_en',
      birthdayBtn: 'menuBirthdayButton_en',
      menuLogo: 'menuLogo_en',

      ms6t1: '*Photo is Set for 4',
      ms6t2: 'and for reference only',
    },
  };

  return (
    <div className={classes.menuPage}>
      {/*red area */}
      <div id={classes.wrapper}>
        <div className={classes.headerBG}>
          <div id={classes.header}>
            {/*image background */}
            <div className={classes.absoluteWrap}>
              <div className={classes.headerMenuRow}>
                {/*menu */}
                <div className={classes.menu}>
                  <button className={classes.menuBtn} onClick={() => goToHomePage('s1')}>
                    {TextMapping[lang].nav1}
                  </button>
                  <button className={classes.menuBtn} onClick={() => goToHomePage('s2')}>
                    {TextMapping[lang].nav2}
                  </button>
                  <button className={classes.menuBtn} onClick={() => goToHomePage('s3')}>
                    {TextMapping[lang].nav3}
                  </button>
                  <button className={classes.menuBtn} onClick={() => goToHomePage('s4')}>
                    {TextMapping[lang].nav4}
                  </button>
                  <button className={classes.menuBtn} onClick={() => goToHomePage('s5')}>
                    {TextMapping[lang].nav5}
                  </button>
                  <button className={classes.menuBtn} onClick={() => goToHomePage('s6')}>
                    {TextMapping[lang].nav6}
                  </button>
                </div>
              </div>
              <div className={classes.contentMediaRow}>
                <div className={classes.headerText}>
                  <img
                    src={require(`../images/${TextMapping[lang].bookingBtn}.png`)}
                    width={130}
                    height={37}
                    alt={'booking_btn_zh'}
                    onClick={() =>
                      gotoPlatform(lang === Lang.zh ? OtherPlatform.InlineApp_zh : OtherPlatform.InlineApp_en)
                    }
                  />
                  <img
                    src={require('../images/fb.png')}
                    width={30}
                    height={30}
                    style={{ marginLeft: 16 }}
                    alt={'fb'}
                    onClick={() => gotoPlatform(OtherPlatform.Facebook)}
                  />
                  <img
                    src={require('../images/instagram.png')}
                    width={30}
                    height={30}
                    style={{ marginLeft: 16 }}
                    alt={'instagram'}
                    onClick={() => gotoPlatform(OtherPlatform.Instagram)}
                  />
                  <img
                    loading="lazy"
                    src={require('../images/whatsapp.png')}
                    width={25}
                    height={25}
                    style={{ marginLeft: 16, marginRight: 16 }}
                    alt={'instagram'}
                    onClick={() => gotoPlatform(OtherPlatform.Whatsapp)}
                  />
                  <Link
                    to="/menu_zh"
                    onClick={() => {
                      setLang(Lang.zh);
                      changeWebsiteTitle(Lang.zh);
                    }}
                  >
                    中
                  </Link>{' '}
                  <div style={{ marginLeft: 10, marginRight: 10 }}>/</div>{' '}
                  <Link
                    to="/menu_en"
                    onClick={() => {
                      setLang(Lang.en);
                      changeWebsiteTitle(Lang.en);
                    }}
                  >
                    ENG
                  </Link>
                </div>
              </div>
              <p className={`${classes.logo} ${classes.noOver}`}>
                <a href="/">
                  <img src={require('../images/logo.png')} alt="宮崎肉本舗" width={340} />
                </a>
              </p>
              <div className={classes.menuLogo1}>
                <img src={require(`../images/page2/${TextMapping[lang].menuLogo}.png`)} alt="宮崎肉本舗" width={820} />
              </div>
            </div>
          </div>

          <div id={classes.setSession} className={classes.session}>
            <div className={classes.textWrapper}>
              <h2 style={{ width: lang === Lang.en ? 560 : 490 }}>{TextMapping[lang].s1t1}</h2>
              <h3>{TextMapping[lang].s1t2}</h3>
              <h5
                style={{
                  width: lang === Lang.en ? 560 : 470,
                  textAlign: lang === Lang.en ? 'left' : undefined,
                }}
              >
                {TextMapping[lang].s1t3}
              </h5>
              <h4 style={{ margin: '10px 0 0 0' }}>{TextMapping[lang].s1t4}</h4>
              <h5 style={{ width: 400, lineHeight: 1.6, textAlign: lang === Lang.en ? 'left' : undefined }}>
                {TextMapping[lang].s1t5_1}
              </h5>
              {lang === Lang.zh ? (
                <>
                  <h5 style={{ width: 400, lineHeight: 1.6 }}>{TextMapping[lang].s1t5_2}</h5>
                  <h5 style={{ width: 400, lineHeight: 1.6 }}>{TextMapping[lang].s1t5_3}</h5>
                </>
              ) : (
                <></>
              )}
              <div className={classes.framedText} style={{ width: lang === Lang.en ? 400 : 265 }}>
                <h4>{TextMapping[lang].s1t6}</h4>
                <h5>{TextMapping[lang].s1t7}</h5>
                <h5>{TextMapping[lang].s1t8}</h5>
              </div>
              <h2 style={{ lineHeight: '1' }}>+</h2>
              <h4>{TextMapping[lang].s1t9}</h4>
              <h5>{TextMapping[lang].s1t10}</h5>
            </div>
            <div className={classes.rightHalfWrapper}>
              <div className={classes.productImgRow}>
                <img src={require('../images/page2/Set_01.jpg')} className={classes.productImg} alt="" />
                <img
                  loading="lazy"
                  src={require('../images/page1/section6/cow_name_plate.png')}
                  style={{ width: 70 }}
                  alt={''}
                />
              </div>
              <div className={classes.remark}>
                {TextMapping[lang].ms6t1}
                <br />
                {TextMapping[lang].ms6t2}
              </div>
            </div>
          </div>

          <div id={classes.setSession} className={classes.session}>
            <div className={classes.rightHalfWrapper}>
              <div className={classes.productImgRow}>
                <img src={require('../images/page2/Set_02.jpg')} className={classes.productImg} alt="" />
              </div>
              <div className={classes.remark}>
                {TextMapping[lang].ms6t1}
                <br />
                {TextMapping[lang].ms6t2}
              </div>
            </div>
            <div className={classes.textWrapper}>
              <h2 style={{ width: lang === Lang.en ? 560 : 490 }}>{TextMapping[lang].s2t1}</h2>
              <h3>{TextMapping[lang].s2t2}</h3>
              {lang === Lang.en ? (
                <>
                  <h5>{TextMapping[lang].s2t3}</h5> <h5>{TextMapping[lang].s2t3_1}</h5>{' '}
                </>
              ) : (
                <></>
              )}

              <h4 style={{ margin: '10px 0 0 0' }}>{TextMapping[lang].s2t4}</h4>
              {lang === Lang.zh ? (
                <>
                  <h5 style={{ width: 400, lineHeight: 1.6 }}>{TextMapping[lang].s2t5_2}</h5>
                </>
              ) : (
                <></>
              )}
              <div className={classes.framedText} style={{ width: lang === Lang.en ? 400 : 265 }}>
                <h4>{TextMapping[lang].s2t6}</h4>
                <h5>{TextMapping[lang].s2t7}</h5>
                <h5>{TextMapping[lang].s2t8}</h5>
              </div>
              <h2 style={{ lineHeight: '1' }}>+</h2>
              <h4>{TextMapping[lang].s2t9}</h4>
              <h5>{TextMapping[lang].s2t10}</h5>
            </div>
          </div>

          <div id={classes.setSession} className={classes.session}>
            <div className={classes.textWrapper}>
              <h2 style={{ width: lang === Lang.en ? 560 : 490 }}>{TextMapping[lang].s3t1}</h2>
              <h3>{TextMapping[lang].s3t2}</h3>
              <h4 style={{ margin: '10px 0 0 0' }}>{TextMapping[lang].s3t4}</h4>
              <h5 style={{ width: 400, lineHeight: 1.6, textAlign: lang === Lang.en ? 'left' : undefined }}>
                {TextMapping[lang].s3t5}
              </h5>
              <h2 style={{ lineHeight: '1' }}>+</h2>
              <h4>{TextMapping[lang].s3t6}</h4>
              <h5 style={{ lineHeight: 1.6, textAlign: lang === Lang.en ? 'left' : undefined }}>
                {TextMapping[lang].s3t6_1}
              </h5>
              <h2 style={{ lineHeight: '1' }}>+</h2>
              <h4>{TextMapping[lang].s3t7_1}</h4>
              <h5>{TextMapping[lang].s3t7_2}</h5>
              <h5>{TextMapping[lang].s3t7}</h5>
            </div>
            <div className={classes.rightHalfWrapper}>
              <div className={classes.productImgRow}>
                <img src={require('../images/page2/Set_03.jpg')} className={classes.productImg} alt="" />
              </div>
              <div className={classes.remark}>
                {TextMapping[lang].ms6t1}
                <br />
                {TextMapping[lang].ms6t2}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.menuBanner}>
          <img src={require(`../images/page1/section7/${TextMapping[lang].ms5Header}.png`)} alt="" width={800} />
        </div>
        <div className={`${classes.session} ${classes.session5}`}>
          <h1>{TextMapping[lang].re}</h1>
          <div className={classes.recommend}>
            <div className={classes.description}>
              <img src={require('../images/page2/recommend1.jpg')} alt="1" />
              <h2>{TextMapping[lang].re1t1}</h2>
              <h6 style={{ width: 330 }}>{TextMapping[lang].re1t2}</h6>
              <div className={classes.framedText}>
                <pre>
                  {TextMapping[lang].re1List.map((item) => (
                    <>{item + '\n'}</>
                  ))}
                </pre>
              </div>
            </div>
            <div className={classes.description}>
              <img src={require('../images/page2/recommend2.jpg')} alt="1" />
              {/* <div style={{ height: 110, display: 'flex', alignItems: 'center' }}> */}
              <h2>{TextMapping[lang].re2t1}</h2>
              {/* </div> */}
              <h6 style={{ width: 360, minHeight: 100 }}>{TextMapping[lang].re2t2}</h6>
              <div className={classes.framedText} style={{ width: 380 }}>
                <pre>
                  {TextMapping[lang].re2List.map((item) => (
                    <>{item + '\n'}</>
                  ))}
                </pre>
              </div>
            </div>
            <div className={classes.description}>
              <img src={require('../images/page2/recommend3.jpg')} alt="1" />
              <h2>{TextMapping[lang].re3t1}</h2>
              <h6 style={{ width: 290 }}>{TextMapping[lang].re3t2} </h6>
              <div className={classes.framedText}>
                <pre>
                  {TextMapping[lang].re3List.map((item) => (
                    <>{item + '\n'}</>
                  ))}
                </pre>
              </div>
            </div>
          </div>
          <h2 className={classes.title}>{TextMapping[lang].items}</h2>
          <div className={classes.items}>
            {_Array.range(0, 3, 1).map((index) => (
              <div
                className={classes.item}
                style={{ marginLeft: index === 1 ? 50 : 0, width: index === 1 ? 280 : undefined }}
              >
                <h2>
                  {TextMapping[lang].itemsList[index * 2][0]}
                  {index === 2 && lang === Lang.zh ? (
                    <span style={{ fontSize: '16px', fontWeight: 400 }}>（日本米使用）</span>
                  ) : (
                    <></>
                  )}
                </h2>
                {index === 2 && lang === Lang.en ? (
                  <span style={{ fontSize: '16px', fontWeight: 400, color: '#b48958' }}>
                    （Fukui Prefecture Koshihikari Rice)
                  </span>
                ) : (
                  <></>
                )}
                <pre>
                  {TextMapping[lang].itemsList[index * 2].slice(1).map((item) => (
                    <>{item + '\n'}</>
                  ))}
                </pre>
                <h2>{TextMapping[lang].itemsList[index * 2 + 1][0]}</h2>
                <pre>
                  {TextMapping[lang].itemsList[index * 2 + 1].slice(1).map((item) => (
                    <>{item + '\n'}</>
                  ))}
                </pre>
              </div>
            ))}
          </div>
          {lang === Lang.en ? (
            <>(The menu will be updated from time to time, and the content is subject to confirmation by staff).</>
          ) : (
            <>(菜單將不時更新，內容以店員回覆確認為準。)</>
          )}
        </div>

        <div className={classes.whiteBanner}>
          <h3>{TextMapping[lang].footer}</h3>
          <div className={classes.flex}>
            <button onClick={() => goToHomePage('s1')}>{TextMapping[lang].nav1}</button>
            <button onClick={() => goToHomePage('s2')}>{TextMapping[lang].nav2}</button>
            <button onClick={() => goToHomePage('s3')}>{TextMapping[lang].nav3}</button>
            <button onClick={() => goToHomePage('s4')}>{TextMapping[lang].nav4}</button>
            <button onClick={() => goToHomePage('s5')}>{TextMapping[lang].nav5}</button>
            <button onClick={() => goToHomePage('s6')}>{TextMapping[lang].nav6}</button>
          </div>
          <p>{TextMapping[lang].footer4}</p>
          <p>{TextMapping[lang].footer5}</p>
          <p>{TextMapping[lang].footer6}</p>
          <h6>miyazaki-nikuhonpo.com.hk</h6>
          <div className={classes.media}>
            <div style={{ width: 10 }} />
            <button
              onClick={() => gotoPlatform(lang === Lang.zh ? OtherPlatform.InlineApp_zh : OtherPlatform.InlineApp_en)}
            >
              <img src={require(`../images/${TextMapping[lang].bookingBtn}.png`)} alt="" width={170} />
            </button>
            <div style={{ width: 10 }} />
            <button>
              <img
                src={require('../images/page2/fb.png')}
                alt=""
                width={40}
                onClick={() => gotoPlatform(OtherPlatform.Facebook)}
              />
            </button>
            <div style={{ width: 10 }} />
            <button>
              <img
                src={require('../images/page2/ig.png')}
                alt=""
                width={40}
                onClick={() => gotoPlatform(OtherPlatform.Instagram)}
              />
            </button>
            <div style={{ width: 10 }} />
            <button>
              <img
                src={require('../images/whatsapp.png')}
                alt=""
                width={40}
                onClick={() => gotoPlatform(OtherPlatform.Whatsapp)}
              />
            </button>
          </div>
          <div className={classes.contact}>
            <h5>{TextMapping[lang].footer2}</h5>
            <h5>{TextMapping[lang].footer3}</h5>
          </div>
        </div>
        <div style={{ height: '100px' }}></div>
      </div>
    </div>
  );
};

export default MenuPage;

class _Array<T> extends Array<T> {
  static range(from: number, to: number, step: number): number[] {
    return Array.from(Array(Math.floor((to - from) / step) + 1)).map((v, k) => from + k * step);
  }
}
